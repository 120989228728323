import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { StrapiFile } from '../../../models/api-interfaces';
import { UtilsService } from '../../../services/utils.service';

export interface ImagePreviewComponentData {
  files: StrapiFile[];
  index: number;
}

@Component({
  selector: 'hf-image-preview',
  templateUrl: './image-preview.component.html',
  styleUrls: ['./image-preview.component.scss'],
})
export class ImagePreviewComponent implements OnInit {
  public selectedImageIndex = 0;
  public isMobile: boolean;

  constructor(
    public dialogRef: MatDialogRef<ImagePreviewComponent>,
    public translate: TranslateService,
    private utilService: UtilsService,
    @Inject(MAT_DIALOG_DATA) public data: ImagePreviewComponentData
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.isMobile = this.utilService.isMobile;
    this.selectedImageIndex = this.data.index;
  }

  public closeDialog() {
    this.dialogRef.close();
  }

  previousImage(): void {
    if (this.selectedImageIndex > 0) {
      this.selectedImageIndex -= 1;
    }
  }

  nextImage(): void {
    if (this.selectedImageIndex < this.data.files.length - 1) {
      this.selectedImageIndex += 1;
    }
  }
}
