<mat-form-field appearance="outline">
        <mat-label>
          {{ datePickerLabel }}
        </mat-label>
        <div class="datepicker-input">
            <input matInput
              [min]="minDate" [max]="maxDate"
              [placeholder]="placeholder"
              [formControl]="datePickerInput"
              [matDatepicker]="picker"
              [matDatepickerFilter]="boundDateFiler" 
              (dateChange)="changeDate($event)"
              [required]="required"
			        [readonly]="readonly">
            <mat-icon class="mat-datepicker-toggle"
              [ngClass]="{'icon-disabled' : disabled}"
              (click)="picker.open()">
            </mat-icon>
        </div>
        <mat-datepicker #picker></mat-datepicker>
        <mat-hint
          class="input-error"
          [id]="null"
          *ngIf="error">
          {{ error }}
        </mat-hint>
</mat-form-field>
