import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HFMenuItem, HfMenuItemCategory } from '../../interfaces/hf-menu-item.interface';
import { UtilsService } from '../../services/utils.service';

@Component({
  selector: 'hf-quick-action-button',
  templateUrl: './quick-action-button.component.html',
  styleUrls: ['./quick-action-button.component.scss'],
})
export class QuickActionMenuComponent implements OnInit {
  @Input() source: HFMenuItem[];
  @Input() text: string;
  @Input() roomsEnabled = true;
  @Input() itemsEnabled = true;
  @Output() openDialog: EventEmitter<HFMenuItem> = new EventEmitter();
  @Output() openSpecificTabAfterRedirect: EventEmitter<number> = new EventEmitter();

  public get isMobile() {
    return this.utils.isMobile;
  }

  public roomMenuItems: HFMenuItem[] = [];
  public itemMenuItems: HFMenuItem[] = [];
  public otherMenuItems: HFMenuItem[] = [];

  constructor(private utils: UtilsService, public translate: TranslateService) {}

  ngOnInit(): void {
    this.roomMenuItems = this.source.filter((x) => x.category === HfMenuItemCategory.ROOMS) ?? [];
    this.itemMenuItems = this.source.filter((x) => x.category === HfMenuItemCategory.RENTALS) ?? [];
    this.otherMenuItems = this.source.filter((x) => x.category === HfMenuItemCategory.OTHER) ?? [];
  }
}
