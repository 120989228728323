<mat-card *ngIf='!completedCangePassword' class="change-password-container shadow">
    <h4>{{'LOGIN.CHANGE_PASSWORD' | translate}}</h4>
    <form [formGroup]='changePasswordForm' (ngSubmit)="submit()" *ngIf="changePasswordForm">
      <p *ngIf="shouldChangePassword" class="change-password-container__change-password">
        {{ 'PROFILE.PASSWORD_CHANGE_REQUIRED' | translate}}
      </p>

        <hf-input-text
            [hint]='oldPasswordError' [placeholder]="'LOGIN.VERIFY_CURRENT_PASSWORD' | translate" required="true" iconAlign="right" icon="lock"
            formControlName="oldPassword" type="password">
        </hf-input-text>
        <div class="change-password-container__newpassword mt-20">
            <hf-input-text
                [hint]='newPasswordError' [placeholder]="'LOGIN.NEW_PASSWORD' | translate" required="true" iconAlign="right" icon="lock"
                formControlName="newPassword" type="password">
            </hf-input-text>
            <hf-input-text
                [hint]='matchPasswordError' [placeholder]="'LOGIN.CONFIRM_NEW_PASSWORD' | translate" required="true" iconAlign="right" icon="lock"
                formControlName="repeatPassword" type="password">
            </hf-input-text>
        </div>

      <div class="hint-container">

        <mat-hint [ngClass]="!this.changePasswordForm.get('newPassword').value ? 'hint-info-color' : this.changePasswordForm.get('newPassword').value && numPatternCheck ? 'hint-success-color' : 'hint-danger-color'">
          <span>
            <mat-icon>{{(this.changePasswordForm.get('newPassword').value && numPatternCheck || !this.changePasswordForm.get('newPassword').value)  ? 'check' : 'close'}}</mat-icon>
            {{'GENERAL.ONE_NUMBER' | translate}}
          </span>
        </mat-hint>

        <mat-hint [ngClass]="!this.changePasswordForm.get('newPassword').value ? 'hint-info-color' : this.changePasswordForm.get('newPassword').value && specialCharPattern ? 'hint-success-color' : 'hint-danger-color'">
          <span>
            <mat-icon>{{(this.changePasswordForm.get('newPassword').value && specialCharPattern || !this.changePasswordForm.get('newPassword').value)  ? 'check' : 'close'}}</mat-icon>
            {{'GENERAL.ONE_SPECIAL_CHARACTER' | translate}}
          </span>
        </mat-hint>
        <mat-hint
        [ngClass]="!this.changePasswordForm.get('newPassword')?.value ? 'hint-info-color' : this.changePasswordForm.get('newPassword')?.value && uppercaseCharacterPattern ? 'hint-success-color' : 'hint-danger-color'">
        <span>
          <mat-icon>{{(this.changePasswordForm.get('newPassword')?.value && uppercaseCharacterPattern || !this.changePasswordForm.get('newPassword')?.value) ? 'check' : 'close'}}</mat-icon>
          {{'GENERAL.ONE_UPPERCASE_CHARACTER' | translate}}
        </span>
      </mat-hint>
      <mat-hint
      [ngClass]="!this.changePasswordForm.get('newPassword').value ? 'hint-info-color' : this.changePasswordForm.get('newPassword').value && minLengthPattern ? 'hint-success-color' : 'hint-danger-color'">
      <span>
        <mat-icon>{{(this.changePasswordForm.get('newPassword').value && minLengthPattern || !this.changePasswordForm.get('newPassword').value) ? 'check' : 'close'}}</mat-icon>
        {{'GENERAL.MIN_LENGTH_8' | translate}}
      </span>
    </mat-hint>
      </div>

   <password-strength-meter class="mt-20" [password]="this.changePasswordForm.get('newPassword').value" [minPasswordLength]="8"></password-strength-meter>
        <div class="submit-button">
            <hf-text-button
                [text]="'Update'" hfColor="primary" styleType="primary"
                type="submit" width="100%" class="submit-button">
            </hf-text-button>
        </div>
    </form>
</mat-card>
<mat-card *ngIf='completedCangePassword' class="change-password-container forgot-password">
    <h2 class="forgot-password-container__step-four__title">{{'FORGOT_PASSWORD.STEP_FOUR.TITLE' | translate}}</h2>
    <h5 class="forgot-password-container__step-four__subtitle">{{'FORGOT_PASSWORD.STEP_FOUR.SUBTITLE' | translate}}</h5>
</mat-card>

