export enum Status {
  All = 'All',
  Open = 'Open',
  Pending = 'Pending',
  Canceled = 'Canceled',
  Approved = 'Approved',
  Rejected = 'Rejected',
  Removed = 'Removed',
  Completed = 'Completed',
  NotCompleted = 'NotCompleted',
}

export interface PartnerRequest {
  id?: string;
  requestFrom?: string; // get account settings from request from
  requestTo: string;
  status?: Status;
  requestStatus?: string;
}
