import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ToastBarComponent } from 'honeyfield-component-library';

@Injectable({
  providedIn: 'root'
})
export class SnackBarService {

  constructor(private toastController: MatSnackBar) {
  }

  public presentToast(title?: string, message?: string, icon?: string, type?: string) {
    this.toastController.openFromComponent(ToastBarComponent, {
      data: { title, message, icon, type },
      duration: 2000,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: 'hf-toast-bar'
    });
  }
}
