import { NgModule } from '@angular/core';
import { CommonModule, DatePipe, DecimalPipe } from '@angular/common';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatChipsModule } from '@angular/material/chips';
import { HttpClient } from '@angular/common/http';
import { TranslateLoader, TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatPaginatorModule } from '@angular/material/paginator';
import { GenericTableHeaderComponent } from './generic-table-header/generic-table-header.component';
import { GenericTablePaginationComponent } from './generic-table-pagination/generic-table-pagination.component';
import { GenericTableDataComponent } from './generic-table-data/generic-table-data.component';
import { GenericTableComponent } from './generic-table.component';
import { GenericTableDataRowComponent } from './generic-table-data-row/generic-table-data-row.component';
import { GenericTableStarComponent } from './generic-table-star/generic-table-star.component';
const DECLARATIONS = [GenericTableStarComponent, GenericTableHeaderComponent, GenericTableDataComponent, GenericTableDataRowComponent, GenericTablePaginationComponent, GenericTableComponent];

// eslint-disable-next-line func-style, prefer-arrow/prefer-arrow-functions
export function httpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: DECLARATIONS,
  imports: [
    CommonModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient],
      },
      defaultLanguage: 'en',
    }),
    MatCheckboxModule,
    MatIconModule,
    MatChipsModule,
    MatProgressBarModule,
    MatPaginatorModule,
    
  ],
  providers: [DatePipe, DecimalPipe],
  exports: [...DECLARATIONS, DatePipe, DecimalPipe],
})
export class GenericTableModule {}
