import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { debounceTime, filter, tap } from 'rxjs/operators';
import { loginUser, registerUser } from '../+store/actions/user.actions';
import { State } from '../+store/reducers';
import { userFeatureKey } from '../+store/reducers/user.reducer';
import { AuthService } from '../auth/auth.service';
import { LoadingIndicationService } from '../services/loading-indication.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  public isLoading = LoadingIndicationService.isLoading.pipe(debounceTime(1));
  public emailForReset = '';
  public completedResetPassword = false;
  private subscriptions = new Subscription();
  constructor(private store: Store<State>, public auth: AuthService, private router: Router) {
    LoadingIndicationService.isLoading.next(false);
  }

  ngOnInit(): void {
    this.prepareStore();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  prepareStore() {
    this.subscriptions.add(
      this.store
        .select((state) => state[userFeatureKey])
        .pipe(
          filter((state) => state.loggedIn),
          tap(() => LoadingIndicationService.isLoading.next(false))
        )
        .subscribe(() => this.router.navigateByUrl('dashboard'))
    );

    this.subscriptions.add(
      this.store
        .select((state) => state[userFeatureKey])
        .pipe(filter((state) => state.registered))
        .subscribe(() => this.router.navigateByUrl('login/confirmation'))
    );

    this.subscriptions.add(
      this.store
        .select((state) => state[userFeatureKey].error)
        .subscribe((err) => console.log('Not logged in / registered: ', err))
    );
  }

  submitLogin(value) {
    const { email, password, persist } = value;
    LoadingIndicationService.isLoading.next(true);
    this.store.dispatch(loginUser({ email, password, persist }));
  }

  submitRegister(value) {
    // when added username field on backend => change this to const { username, email, password }
    // and add the value throughout the app
    const { email, password, fullName } = value;
    this.store.dispatch(registerUser({ email, password, fullName }));
  }

  forgotPassword(value) {
    this.emailForReset = value[0][1].email;
    this.auth.initiateForgotPassword(this.emailForReset).subscribe();
    // to do
  }
  resetPassword(value) {
    this.auth
      .resetPassword(this.emailForReset, value[0][1].verificationCode, value[0][1].newPassword)
      .subscribe((res) => {
        this.completedResetPassword = true;
      });
  }
}
