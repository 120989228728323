<div class="toast-bar-container {{type}}">

  <div class="type-indicator">
    <div class="left-border">
    </div>

    <mat-icon>
      {{icon}}
    </mat-icon>
  </div>

  <div class="text-container">
    <h5>
      {{ title | translate }}
    </h5>

    <p>
      {{ message | translate }}
    </p>
  </div>

  <hf-icon-button
    icon="close"
    styleType="ghost"
    (click)="dismissToast()">
  </hf-icon-button>
</div>
