import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'hf-star',
  templateUrl: './generic-table-star.component.html',
  styleUrls: ['./generic-table-star.component.css']
})
export class GenericTableStarComponent implements OnInit {
  private readonly NUMBER_OF_STARS = 5;

  @Input()
  category = 0;

  constructor() { }

  ngOnInit(): void {}

  private get numberOfFullStars(): number {
    return Math.floor(this.category);
  }

  private get numberOfEmptyStars(): number {
    return this.NUMBER_OF_STARS - Math.ceil(this.category);
  }

  get fullStars(): any[] {
    return Array(this.numberOfFullStars);
  }

  get emptyStars(): any[] {
    return Array(this.numberOfEmptyStars);
  }

}
