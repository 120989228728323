import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';

import { ImageRef, StrapiFile, UploadFileData } from '@shared-library-models/api-interfaces';
import { LoadingIndicationService } from './loading-indication.service';
import { SnackBarService, UploadFileService } from 'honeyfield-shared-library';
import { HttpEvent, HttpEventType } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class UploadHelperService {
  public progress = 0;

  private _uploadProgessSubject: ReplaySubject<any> = new ReplaySubject<any>();
  uploadProgess$: Observable<any> = this._uploadProgessSubject.asObservable();

  constructor(
    private snackBar: SnackBarService,
    public fileUploadService: UploadFileService,
    private translate: TranslateService
  ) {}

  uploadFile(
    uploadedFiles: FileList,
    imageRef: ImageRef,
    files?: StrapiFile[],
    fileData: UploadFileData = { maximumSize: 5, allowedFormats: ['jpg', 'png', 'jpeg'] }
  ): Observable<StrapiFile[]> {
    const data = new FormData();
    // eslint-disable-next-line prefer-const
    const message = this.translate.instant('IMAGE.UPLOAD_SUCCESS');
    let counter = 0;

    /* eslint-disable @typescript-eslint/prefer-for-of */
    for (let index = 0; index < uploadedFiles.length; index++) {
      if (uploadedFiles[index].size > fileData.maximumSize * 1024 * 1024) {
        this.snackBar.presentToast('Error', this.translate.instant('IMAGE.INVALID_FILE_SIZE'), 'cancel', 'error');
      } else if (
        !fileData.allowedFormats.includes(
          uploadedFiles[index].type?.substring(uploadedFiles[index].type?.indexOf('/') + 1)?.toLocaleLowerCase()
        )
      ) {
        this.snackBar.presentToast('Error', this.translate.instant('IMAGE.INVALID_FILE_FORMAT'), 'cancel', 'error');
      } else {
        const element = uploadedFiles[index];
        data.append('files', element, element.name);
        counter++;
      }
    }

    if (counter === 0) {
      if (files && files.length) {
        return of([...files]);
      } else {
        return of([]);
      }
    }

    data.append('ref', imageRef.ref);
    data.append('refId', imageRef.refId);
    data.append('field', imageRef.field);

    if (imageRef.source) data.append('source', imageRef.source);

    const request = this.fileUploadService.uploadFile(data);

    LoadingIndicationService.isLoading.next(true);
    return request.pipe(
      map(
        (response: StrapiFile[]) => {
          this.snackBar.presentToast(message, '', 'check_circle', 'positive');

          if (files) {
            files = [...files, ...response];
          } else {
            files = [...response];
          }

          LoadingIndicationService.isLoading.next(false);

          return files;
        },
        (error) => {
          this.snackBar.presentToast('Error', this.translate.instant('IMAGE.UPLOAD_FAILED'), 'cancel', 'error');
          console.error('Error saving file: ', error);
          LoadingIndicationService.isLoading.next(false);
        }
      )
    );
  }

  deleteFile(id: string, files: StrapiFile[]): Observable<StrapiFile[]> {
    const request = this.fileUploadService.deleteFileById(id);
    const message = this.translate.instant('IMAGE.REMOVED_SUCCESS');
    LoadingIndicationService.isLoading.next(true);

    return request.pipe(
      map(
        () => {
          this.snackBar.presentToast(message, '', 'info', 'info');

          const file = files && files.length ? files.find((x) => x._id === id) : null;
          const deletedFileIndex = file ? files.indexOf(file) : -1;
          if (deletedFileIndex > -1) {
            files.splice(deletedFileIndex, 1);
          }

          LoadingIndicationService.isLoading.next(false);

          return files;
        },
        (error) => {
          this.snackBar.presentToast('Error', this.translate.instant('IMAGE.REMOVED_FAILED'), 'cancel', 'error');
          console.error('Error saving file: ', error);
          LoadingIndicationService.isLoading.next(false);
        }
      )
    );
  }

  uploadFileV2(
    uploadedFiles: FileList,
    imageRef: ImageRef,
    files?: StrapiFile[],
    fileData: UploadFileData = { maximumSize: 5, allowedFormats: ['jpg', 'png', 'jpeg'] }
  ): Observable<any> {
    const data = new FormData();
    // eslint-disable-next-line prefer-const
    const message = this.translate.instant('IMAGE.UPLOAD_SUCCESS');
    let counter = 0;

    /* eslint-disable @typescript-eslint/prefer-for-of */
    for (let index = 0; index < uploadedFiles.length; index++) {
      if (uploadedFiles[index].size > fileData.maximumSize * 1024 * 1024) {
        this.snackBar.presentToast('Error', this.translate.instant('IMAGE.INVALID_FILE_SIZE'), 'cancel', 'error');
      } else if (
        !fileData.allowedFormats.includes(
          uploadedFiles[index].type?.substring(uploadedFiles[index].type?.indexOf('/') + 1)?.toLocaleLowerCase()
        )
      ) {
        this.snackBar.presentToast('Error', this.translate.instant('IMAGE.INVALID_FILE_FORMAT'), 'cancel', 'error');
      } else {
        const element = uploadedFiles[index];
        data.append('files', element, element.name);
        counter++;
      }
    }

    if (counter === 0) {
      if (files && files.length) {
        return of([...files]);
      } else {
        return of([]);
      }
    }

    data.append('ref', imageRef.ref);
    data.append('refId', imageRef.refId);
    data.append('field', imageRef.field);

    if (imageRef.source) data.append('source', imageRef.source);

    const request = this.fileUploadService.uploadFileV2(data);

    LoadingIndicationService.isLoading.next(true);
    return request.pipe(
      map(
        (event: HttpEvent<any>) => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              const eventTotal = event.total ? event.total : 0;
              this.progress = Math.round((event.loaded / eventTotal) * 100);
              this._uploadProgessSubject.next(this.progress);
              break;
            case HttpEventType.Response:
              this.snackBar.presentToast(message, '', 'check_circle', 'positive');

              if (files) {
                files = [...files, ...event.body];
              } else {
                files = [...event.body];
              }

              LoadingIndicationService.isLoading.next(false);

              setTimeout(() => {
                this.progress = 0;
                this._uploadProgessSubject.next(this.progress);
              }, 1500);

              return files;
            default:
              this.progress = 0;
              this._uploadProgessSubject.next(this.progress);
              break;
          }
        },
        (error) => {
          this.snackBar.presentToast('Error', this.translate.instant('IMAGE.UPLOAD_FAILED'), 'cancel', 'error');
          console.error('Error saving file: ', error);
          LoadingIndicationService.isLoading.next(false);
          this.progress = 0;
          this._uploadProgessSubject.next(this.progress);
        }
      )
    );
  }
}
