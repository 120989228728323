import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ChatRoutingModule } from './chat-routing.module';
import { ChatComponent } from './chat.component';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { PaginationDirective } from './directives/paginator.directive';
import { MessagesBoxComponent } from './components/messages-box/messages-box.component';
import { LeaveReviewModalComponent } from './modals/leave-review-modal/leave-review-modal.component';
import { MatDialogModule } from '@angular/material/dialog';
import { StarRatingComponent } from './components/star-rating/star-rating.component';
import { LastMessageDatePipe } from './pipes/LastMessageDatePipe';
import { TranslateModule } from '@ngx-translate/core';
import { ReservationMessageComponent } from './components/reservation-message/reservation-message.component';
import { TextIconButtonModule } from '../../components/text-icon-button/text-icon-button.module';
import { UserAvatarModule } from '../../components/user-avatar/user-avatar.module';

@NgModule({
  declarations: [
    ChatComponent,
    PaginatorComponent,
    PaginationDirective,
    MessagesBoxComponent,
    LeaveReviewModalComponent,
    StarRatingComponent,
    LastMessageDatePipe,
    ReservationMessageComponent,
  ],
  imports: [
    CommonModule,
    ChatRoutingModule,
    MatInputModule,
    MatIconModule,
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    TextIconButtonModule,
    UserAvatarModule,
  ],
  exports: [ChatComponent],
})
export class ChatModule {}
