<ng-container [ngSwitch]="styleType">
  <button
    [type]="type"
    *ngSwitchCase="'primary'"
    mat-flat-button
    class="icon-btn btn-primary-type"
    matTooltipPosition="above"
    [matTooltip]="tooltipContent"
    [hfColor]="hfColor"
    [disabled]="disabled"
    [style.min-width]="width"
    [style.min-height]="height"
    [style.border-radius]="borderRadius"
    [class.smaller-icon]="iconResize"
  >
    <img *ngIf="iconUrl" [src]="iconUrl" [alt]="tooltipContent" />
    <mat-icon *ngIf="icon">
      {{ icon }}
    </mat-icon>
  </button>
  <button
    [type]="type"
    *ngSwitchCase="'secondary'"
    mat-stroked-button
    class="icon-btn btn-secondary-type"
    matTooltipPosition="above"
    [matTooltip]="tooltipContent"
    [hfColor]="hfColor"
    [disabled]="disabled"
    [style.width]="width"
    [style.min-height]="height"
    [style.border-radius]="borderRadius"
  >
    <img *ngIf="iconUrl" [src]="iconUrl" [alt]="tooltipContent" />
    <mat-icon *ngIf="icon">
      {{ icon }}
    </mat-icon>
  </button>
  <button
    [type]="type"
    *ngSwitchCase="'ghost'"
    mat-button
    class="icon-btn btn-ghost-type"
    matTooltipPosition="above"
    [matTooltip]="tooltipContent"
    [hfColor]="hfColor"
    [disabled]="disabled"
    [style.width]="width"
    [style.min-height]="height"
    [style.border-radius]="borderRadius"
  >
    <img *ngIf="iconUrl" [src]="iconUrl" [alt]="tooltipContent" />
    <mat-icon *ngIf="icon">
      {{ icon }}
    </mat-icon>
  </button>
</ng-container>
