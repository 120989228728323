<mat-form-field [style.width]="width" appearance="outline" [class.custom-search]="addBorderRadius">
  <input
    matInput
    type="text"
    [placeholder]="placeholder"
    [value]="value"
    [type]="type"
    (input)="doInput($event)"
    (blur)="doBlur()"
    (keyup.enter)="emitValue()"
    [formControl]="searchInput"
    [matAutocomplete]="auto"
  />
  <mat-icon *ngIf="(icon && iconAlign === 'left') || (icon && !iconAlign)" matPrefix (click)="emitValue()">
    {{ icon }}
  </mat-icon>
  <mat-icon *ngIf="icon && iconAlign === 'right'" matSuffix (click)="emitValue()">
    {{ icon }}
  </mat-icon>
  <div class="mobile-search">
    <hf-icon-button styleType="primary" [borderRadius]="addBorderRadius ? '50%' : ''" [iconResize]="true" [icon]="'search'"
      hfColor="primary" bold="true" (click)="emitValue()">
    </hf-icon-button>
  </div>
</mat-form-field>

<mat-autocomplete
  #auto="matAutocomplete"
  autoActiveFirstOption
  [displayWith]="displayFn"
  (optionSelected)="updateSelection($event)"
>
  <mat-option *ngFor="let item of filteredItems | async" [value]="item.value">
    {{ item.label }}
  </mat-option>
</mat-autocomplete>
