import { Component, OnInit } from '@angular/core';
import { confirmationViewed } from '../../+store/actions/user.actions';
import { Store } from '@ngrx/store';
import { State } from '../../+store/reducers';

@Component({
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent implements OnInit {

  constructor(private store: Store<State>) {
  }

  ngOnInit(): void {

    this.store.dispatch(confirmationViewed({}));
  }

}
