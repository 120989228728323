import { Directive, ElementRef, Input } from '@angular/core';
import { Router } from '@angular/router';

@Directive({
  selector: '[hfMenuItem]'
})
export class MenuItemDirective {

 // @Input() url: string;

  constructor(private element: ElementRef, private router: Router) {
   // element.nativeElement.classList.add('menu-item');
  }

}
