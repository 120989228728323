import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { LoginRegisterComponent } from './login-register.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { RegisterFormComponent } from './register-form/register-form.component';
import { InputTextModule } from '../../components/input-text/input-text.module';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { PasswordStrengthMeterModule } from 'angular-password-strength-meter';
import { CheckboxModule } from '../../components/checkbox/checkbox.module';
import { TextButtonModule } from '../../components/text-button/text-button.module';
import { CardModule } from '../../components/card/card.module';
import { LoginFormComponent } from './login-form/login-form.component';
import { MatCardModule } from '@angular/material/card';
import { RecaptchaModule } from 'ng-recaptcha';
import { ColorModule } from '../../components/colors/color.module';
import { TextIconButtonModule } from '../../components/text-icon-button/text-icon-button.module';


@NgModule({
  declarations: [LoginRegisterComponent, ForgotPasswordComponent, RegisterFormComponent, LoginFormComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    InputTextModule,
    TranslateModule,
    MatIconModule,
    PasswordStrengthMeterModule,
    CheckboxModule,
    TextButtonModule,
    TextIconButtonModule,
    CardModule,
    ColorModule,
    MatCardModule,
    RecaptchaModule
  ],
  exports: [LoginRegisterComponent, ForgotPasswordComponent, RegisterFormComponent, LoginFormComponent]
})
export class LoginRegisterModule {
}
