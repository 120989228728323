<mat-form-field appearance="outline">
  <mat-label>
    {{ placeholder }}
  </mat-label>
  <mat-icon *ngIf="icon" matSuffix>
    {{ icon }}
  </mat-icon>
  <mat-select disableOptionCentering
              [panelClass]="isMultiple ? 'mat-multiselect-custom-position' : 'mat-select-custom-position'" #selection
              matNativeControl [formControl]="selectInput" (valueChange)="doInput(selection.value)"
              [required]="required" [multiple]="isMultiple" (selectionChange)="onSelection($event)">
    <ng-container *ngFor="let selectItem of source">
      <mat-optgroup label="{{selectItem.label | translate}}">
        <mat-option *ngFor="let item of selectItem.selectItem; let i = index" [id]="'option' + i" [value]="item.value"
                    [disabled]="item.disabled || (max !== null && this.selectInput.value.length >= max && !this.selectInput.value.includes(item.value))">
          <ng-container *ngIf="!showImages; else img">
            {{ item.label | translate }} {{ ' ' + item.additionalText ? item.additionalText : '' }}
          </ng-container>
          <span *ngIf="item.disabled" class="not-available">{{ 'GENERAL.NOT_AVAILABLE' | translate }}</span>
          <ng-template #img>
            <div class="img-item">
              <img class="img" [src]="item.imgURL"/>
              <span>{{ item.label }}</span>
            </div>
          </ng-template>
        </mat-option>
      </mat-optgroup>
    </ng-container>
  </mat-select>
</mat-form-field>

