<mat-progress-bar mode="indeterminate" *ngIf="isLoading | async"></mat-progress-bar>
<hf-login-register
  [logo]="'../assets/Logo.png'"
  [headerLogo]="true"
  (eventLogin)="submitLogin($event)"
  (eventRegister)="submitRegister($event)"
  (eventForgotPassword)="forgotPassword($event)"
  (eventResetPassword)="resetPassword($event)"
  [completedResetPassword]="completedResetPassword"
  [hideRecaptcha]="true">
</hf-login-register>
