import { AsyncValidatorFn, ValidatorFn } from '@angular/forms';

export interface HFValidationConfig {
  login?: HFValidatorOption;
  register?: HFValidatorOption;
}

export interface HFValidatorOption {
  firstName?: HFValidatorField;
  lastName?: HFValidatorField;
  email?: HFValidatorField;
  password?: HFValidatorField;
  repeatPassword?: HFValidatorField;
}

export interface HFValidatorField {
  validator?: ValidatorFn[] | AsyncValidatorFn[];
  errorMessages?: ErrorMessage;
}

export interface ErrorMessage {
  required?: string;
  invalid?: string;
  minLength?: string;
  notSame?: string;
}
