import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PopupAlertData } from '../../models/api-interfaces';

@Component({
  selector: 'hf-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {
  public color = 'primary';

  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PopupAlertData) { }

  performAction(response: boolean): void {
    this.dialogRef.close(response);
  }

  ngOnInit() {
    if (!this.data) {
      this.data = { title: 'Hi', text: 'Are you sure that you want to proceed?', cancelActionText: 'No', confirmActionText: 'Yes' };
    }

    if (this.data?.color) 
        this.color = this.data.color;
  }
}