import { Action, createReducer, on } from '@ngrx/store';
import * as UserActions from '../actions/user.actions';
import { User } from 'honeyfield-component-library';

export const userFeatureKey = 'user';

export interface UserState {
  readonly user: User;
  readonly loggedIn: boolean;
  readonly registered: boolean;
  readonly error: string;
}

export const initialState: UserState = {
  user: null,
  loggedIn: false,
  registered: false,
  error: null,
};

const userReducer = createReducer(
  initialState,

  on(UserActions.logoutUser, (state) => ({ ...state, loggedIn: false, user: null })),
  on(UserActions.loginUserSuccess, (state, action) => ({ ...state, loggedIn: true, user: action.user })),
  on(UserActions.loginUserFailure, (state) => ({ ...state, loggedIn: false, user: null })),
  on(UserActions.registerUserSuccess, (state, action) => ({ ...state, registered: true, loggedIn: false, user: null })),
  on(UserActions.confirmationViewed, (state, action) => ({ ...state, registered: false, loggedIn: false, user: null })),
  on(UserActions.registerUserFailure, (state) => ({ ...state, registered: false, loggedIn: false, user: null }))
);

// eslint-disable-next-line func-style, prefer-arrow/prefer-arrow-functions
export function reducer(state: UserState | undefined, action: Action) {
  return userReducer(state, action);
}
