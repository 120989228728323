<div class="request" [class.pointer]="request?.status === 'Approved'" (click)="showRental(request)">
  <div class="row data-holder">
    <img [src]="request?.rentable_item?.images[0]?.url ?? 'assets/images/empty-img.png'" />
    <span class="item-row">
      <span class="row item-company">
        <span>{{ message?.partner?.Header ?? message?.receiver?.firstname + ' ' + message?.receiver?.lastname }}</span>
        <span class="name">{{ 'RENTALS.PRICE' | translate }}:</span>
      </span>
      <span class="row item-name">
        <span class="name">{{ request?.rentable_item?.Name }}</span>
        <span class="price">{{ request?.totalPrice | currency: '€' }}</span>
      </span>
    </span>
  </div>
  <div class="row column">
    <div class="request__dates">
      <span class="row">
        <img class="icon" src="assets/icons/check-in.svg" />
        <span>
          {{ 'GENERAL.PICKUP' | translate }}:
          <span class="date">{{ request?.start | date: (dailyPrice ? 'MMM d, y' : 'MMM d, y, h:mm') }}</span>
        </span>
      </span>
      <span class="row">
        <img class="icon" src="assets/icons/check-out.svg" />
        <span>
          {{ 'GENERAL.RETURN' | translate }}:
          <span class="date">{{ request?.end | date: (dailyPrice ? 'MMM d, y' : 'MMM d, y, h:mm') }}</span>
        </span>
      </span>
    </div>
    <div class="row request__actions">
      <ng-container *ngIf="request?.status === 'Pending'; else notPending">
        <hf-text-icon-button
          [class.first]="lessor"
          hfColor="grayscale"
          styleType="secondary"
          icon="close"
          [width]="'100%'"
          [text]="translate.get('CHAT.DECLINE') | async"
          (click)="onMessageAction(lessor ? 'Rejected' : 'Canceled')"></hf-text-icon-button>
        <hf-text-icon-button
          *ngIf="lessor"
          hfColor="primary"
          styleType="primary"
          icon="check"
          [width]="'100%'"
          (click)="onMessageAction('Approved')"
          [text]="translate.get('CHAT.APPROVE') | async"></hf-text-icon-button>
      </ng-container>
      <ng-template #notPending>
        <span *ngIf="request?.status === 'Approved'" class="action confirm selected">
          <mat-icon>check</mat-icon>
          {{ 'CHAT.REQUEST_APPROVE' | translate }}
        </span>
        <span class="action decline selected" *ngIf="request?.status !== 'Pending' && request?.status !== 'Approved'">
          <mat-icon>close</mat-icon>
          {{ 'CHAT.REQUEST_DECLINE' | translate }}
        </span>
      </ng-template>
    </div>
  </div>
</div>
