import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { AuthService } from './auth.service';
import { catchError, map, tap } from 'rxjs/operators';
import { LoadingIndicationService } from '../services/loading-indication.service';
import { SnackBarService } from '../services/snack-bar.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private request:  HttpRequest<any>;
  private next: HttpHandler;
  private errorPipe = catchError(err => {
    const errMessage = Array.isArray(err.error.message) ? err.error.message[0]: err.error.message;
    if(err.status === 401) {
      if(AuthService.token) {
          this.authService.refreshToken().subscribe(result => {
              this.intercept(this.request, this.next).subscribe();
          });
      } else {
        this.toastService.presentToast(errMessage, '', 'cancel', 'error');
      }


    } else {
        this.toastService.presentToast(errMessage, '', 'cancel', 'error');
    }
    LoadingIndicationService.isLoading.next(false);
    return of([]);
  });

  constructor(
    private authService: AuthService,
    private toastService: SnackBarService,
    ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any> | any> {
    if(!request.url.includes('refresh')) {
      this.request = request;
      this.next = next;
    }
    if (request.url.includes('account/login') || request.url.includes('account/register')) {
      return next.handle(request).pipe(this.errorPipe);
    }

    if (this.authService.isUserLoggedIn()) {
      const tokenExpTime = this.authService.checkTokenExpirationTime();
      //If token expiration time is less than 5 mins we need to refresh it
      if(tokenExpTime > 0 && tokenExpTime < 300 && !request.url.includes('refresh') && !AuthService.refreshing) {
        this.authService.refreshToken().subscribe();
      }
      //If token expiration time is expired navigate to login
      if(tokenExpTime < 0) {
        this.authService.navigateToLogout();
      }

      const headers = {
        Authorization: `Bearer ${AuthService.token}`,
        'Content-Type': 'application/json'
      };

      request = request.clone({
        setHeaders: headers
      });
      if(!request.url.includes('refresh')) {
        this.request = request;
      }
    }
    return next.handle(request).pipe(this.errorPipe);
  }
}
