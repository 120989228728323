<mat-toolbar>
  <hf-icon-button (click)="toggleSidebar()" styleType="ghost" *ngIf="isMobile" icon="menu"></hf-icon-button>
  <hf-top-date></hf-top-date>
  <span class="toolbar-spacer"></span>
  <ng-content></ng-content>
  <hf-dropdown-menu
    icon="keyboard_arrow_down"
    [topLabel]="fullName"
    [source]="dropdownItems"
    [useAvatar]="true"
  ></hf-dropdown-menu>
</mat-toolbar>
