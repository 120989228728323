<div class="menu-item item-{{colorScheme}}" [class.disabled]="disabled" *ngIf="!disabled"
               (click)="navigate($event)"
               routerLinkActive="active" [ngClass]="{'increased-padding': increasePadding}">
  <mat-icon [class]="iconLeft" [ngStyle]="{'background-image': 'url(' + iconPath + ')'}">
  </mat-icon>
  <span>{{ item | translate }}</span>
  <mat-icon *ngIf="!counterActive" class="icon-right"
            (click)="rightIconClick()">
    {{ iconRight }}
  </mat-icon>
  <div class="counter" *ngIf="counterActive && ( item === 'NAV.REQUESTS' || item === 'NAV.PARTNERS' || item === 'NAV.MESSAGES' || 'PROFILE_NAV.MESSAGES') && count > 0">
    {{ count }}
  </div>
</div>