import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { RequestService, SnackBarService, SocketsService } from 'honeyfield-shared-library';
import { Observable, Subject, takeUntil } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'hf-reservation-message',
  templateUrl: './reservation-message.component.html',
  styleUrls: ['./reservation-message.component.scss'],
})
export class ReservationMessageComponent implements OnInit {
  @Input() message: any;
  @Input() currentUser: any;

  request: any;
  recipient: any;
  lessor: boolean;
  dailyPrice = false;

  private destroy$: Subject<void> = new Subject<void>();
  public requestChanges: Observable<any>;

  constructor(
    private requestService: RequestService,
    private snackBar: SnackBarService,
    private socketsService: SocketsService,
    public translate: TranslateService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.request = this.message?.request;
    this.lessor = this.request?.request_recipient === this.currentUser?._id;
    this.dailyPrice = this.request?.rentable_item?.priceSchema?.unit === 'Day';
    if (this.request?.status === 'Pending') {
      this.listenReservationChanges();
    }
  }

  onMessageAction(_action) {
    if (this.request?.status === 'Pending') {
      this.handleRequestAction(_action);
    }
  }

  showRental(request) {
    if (request.status === 'Approved' && this.router.url.includes('profile')) {
      this.router.navigate(['profile', 'rentings', request._id]);
    }
  }

  handleRequestAction(status) {
    switch (status) {
      case 'Approved':
        this.requestService
          .approveRequest(this.request._id)
          .pipe(takeUntil(this.destroy$))
          .subscribe(() => {
            this.onActionConfirm(status);
          });
        break;
      case 'Canceled':
        this.requestService
          .cancelRequest(this.request._id)
          .pipe(takeUntil(this.destroy$))
          .subscribe(() => {
            this.onActionConfirm(status);
          });
        break;
      case 'Rejected':
        this.requestService
          .rejectRequest(this.request._id)
          .pipe(takeUntil(this.destroy$))
          .subscribe(() => {
            this.onActionConfirm(status);
          });
        break;
      default:
        break;
    }
  }

  onActionConfirm(status) {
    const title = 'GENERAL.SUCCESS';
    const message = 'RENTALS.' + status.toUpperCase();
    this.snackBar.presentToast(title, message, 'check_circle', 'positive');
  }

  listenReservationChanges() {
    if (!this.socketsService) return;
    this.requestChanges = this.socketsService.getSocketEventI(`${this.request?._id}/request/messages`);
    this.requestChanges.pipe(takeUntil(this.destroy$)).subscribe((requestChanges) => {
      if (requestChanges?.requestId === this.request?._id) {
        this.request.status = requestChanges.status;
      }
    });
  }
}
