import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export interface OnBoardingListElement {
  title: string;
  description: string;
  buttonIcon?: string;
  onboardingLink?: string;
  checked: boolean;
}

@Component({
  selector: 'hf-onboarding-list-element',
  templateUrl: './onboarding-list-element.component.html',
  styleUrls: ['./onboarding-list-element.component.scss']
})
export class OnboardingListElementComponent implements OnInit {

  @Input() source: OnBoardingListElement[];
  @Output() navigate: EventEmitter<number> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  continueOnboarding(index: number) {
    this.navigate.emit(index);
  }

}
