import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginRegisterV2Component } from './login-register-v2.component';
import { RegistrationV2Component } from './registration-v2/registration-v2.component';
import { LoginV2Component } from './login-v2/login-v2.component';
import { ForgotPasswordV2Component } from './forgot-password-v2/forgot-password-v2.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { PasswordStrengthMeterModule } from 'angular-password-strength-meter';
import { CheckboxModule } from '../../components/checkbox/checkbox.module';
import { TextButtonModule } from '../../components/text-button/text-button.module';
import { CardModule } from '../../components/card/card.module';
import { MatCardModule } from '@angular/material/card';
import { RecaptchaModule } from 'ng-recaptcha';
import { ColorModule } from '../../components/colors/color.module';
import { TextIconButtonModule } from '../../components/text-icon-button/text-icon-button.module';
import { InputTextModule } from '../../components/input-text/input-text.module';
import { MatTabsModule } from '@angular/material/tabs';

@NgModule({
  declarations: [
    LoginRegisterV2Component,
    RegistrationV2Component,
    LoginV2Component,
    ForgotPasswordV2Component
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    InputTextModule,
    TranslateModule,
    MatIconModule,
    PasswordStrengthMeterModule,
    CheckboxModule,
    TextButtonModule,
    TextIconButtonModule,
    CardModule,
    ColorModule,
    MatCardModule,
    RecaptchaModule,
    MatTabsModule
  ],
  exports: [
    LoginRegisterV2Component,
    RegistrationV2Component,
    LoginV2Component,
    ForgotPasswordV2Component
  ]
})
export class LoginRegisterV2Module { }
