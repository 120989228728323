<div class="logout">
  <img class="logout__logo" src='../../../assets/rent2blogo.png'>
  <mat-card class="logout__card">
    <img src='../../../assets/Logout.png'>
    <h1 class="logout__card__title">{{ 'LOGOUT.TITLE' | translate}}</h1>
    <h2 class="logout__card__subheading"><strong>{{ 'LOGOUT.SUBTITLE' | translate}}</strong></h2>
    <mat-card-actions>
      <hf-text-button class="logout__card__go-to-login"
                      styleType="primary"
                      routerLink="/login"
                      hfColor="primary" [text]="('LOGOUT.GOTO_LOGIN' | translate)"></hf-text-button>
    </mat-card-actions>
  </mat-card>
</div>
