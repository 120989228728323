import { Injectable } from '@angular/core';
import { EndColumnsInfo, StartColumnsInfo } from '../../models/api-interfaces';
import { UtilsService } from '../../services/utils.service';
import { GenericTableDef } from './table-data.dto';

export interface GenericTableGridConfig {
  selectable: boolean;
  showStart: boolean;
  showActions: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class GenericTableService {

  constructor(private utilsService: UtilsService) {
  }

  public createGridTemplate(gridConfig: GenericTableGridConfig, columnData: GenericTableDef[], startColumnsInfo: StartColumnsInfo[], endColumnsInfo: EndColumnsInfo[]): string {

    const gridTemplateString: string[] = [];

    if (gridConfig.selectable) {
      gridTemplateString.push('4rem');
    } else {
      gridTemplateString.push('1rem');
    }

    if (startColumnsInfo != null) {
      startColumnsInfo.forEach(el =>{
        gridTemplateString.push(el.widthPx);
      })
    }
    else if(gridConfig.showStart)
    {
      gridTemplateString.push('50px');
    }

    columnData.forEach(elem => {
        gridTemplateString.push(elem.gridItemSize ? elem.gridItemSize() : '1fr');
    });

    if (endColumnsInfo != null) {
      endColumnsInfo.forEach(el => {
        gridTemplateString.push(el.widthPx);
      })
    }
    else if(gridConfig.showActions) {
      if (!this.utilsService.isMobile) {
        gridTemplateString.push('200px');
      } else {
        gridTemplateString.push('100px');
      }
    }

    return gridTemplateString.join(' ');
  }
}
