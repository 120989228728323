import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  private doLogout: Subject<string> = new Subject();

  constructor(private http: HttpClient) {}

  public emitLogout(): void {
    this.doLogout.next('');
  }

  public getLogoutAsObservable(): Observable<string> {
    return this.doLogout.asObservable();
  }
}
