import { Component, ContentChild, ViewEncapsulation, OnInit, Output, EventEmitter, OnDestroy, Input } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { PageEvent } from '@angular/material/paginator';

import { SelectionModel } from '@angular/cdk/collections';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GenericTableOptions } from './table-data.dto';
import { GenericTableHeaderComponent } from './generic-table-header/generic-table-header.component';
import { GenericTableDataComponent } from './generic-table-data/generic-table-data.component';

export const tableAnimation = trigger('detailExpand', [state('collapsed', style({ height: '0px', minHeight: '0' })), state('expanded', style({ height: '*' })), transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))]);

@Component({
  selector: 'hf-generic-table',
  templateUrl: './generic-table.component.html',
  styleUrls: ['./generic-table.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [tableAnimation],
})
export class GenericTableComponent<T> implements OnInit, OnDestroy {
  @Input() tableData: T[];
  @Input() tableOptions: GenericTableOptions = {
    page: 0,
    pageSize: 10,
    totalItems: 0,
  };
  @Input() loading = false;

  public selection = new SelectionModel<T>(true, []);
  @Output() selectionChanged: EventEmitter<T[]> = new EventEmitter<T[]>();

  @Output() paginationChanged: EventEmitter<PageEvent> = new EventEmitter();

  @Input()
  public showPagination = false;

  private destroy$: Subject<boolean> = new Subject<boolean>();

  private _headerComponent: GenericTableHeaderComponent<T>;
  @ContentChild(GenericTableHeaderComponent)
  public set headerComponent(val: GenericTableHeaderComponent<T>) {
    this._headerComponent = val;
    if (!val) {
      return;
    }

    this.headerComponent.selection = this.selection;
    this.headerComponent.selectionEmitter.pipe(takeUntil(this.destroy$)).subscribe((sel) => {
      this.selection = sel;
      this.dataComponent.selection = this.selection;
      this.selectionChanged.emit(this.selection.selected);
    });
  }

  public get headerComponent(): GenericTableHeaderComponent<T> {
    return this._headerComponent;
  }

  private _dataComponent: GenericTableDataComponent<T>;
  @ContentChild(GenericTableDataComponent)
  public set dataComponent(val: GenericTableDataComponent<T>) {
    this._dataComponent = val;

    if (!val) {
      return;
    }

    this.dataComponent.selection = this.selection;
    this.dataComponent.selectionEmitter.pipe(takeUntil(this.destroy$)).subscribe((sel) => {
      this.selection = sel;
      this.headerComponent.selection = this.selection;
      this.selectionChanged.emit(this.selection.selected);
    });
  }

  public get dataComponent(): GenericTableDataComponent<T> {
    return this._dataComponent;
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.destroy$.next(true);
  }
}
