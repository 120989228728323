import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatSnackBar, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { HfToastBarInterface } from '../../interfaces/hf-toast-bar.interface';

@Component({
  selector: 'hf-toast-bar',
  templateUrl: './toast-bar.component.html',
  styleUrls: ['./toast-bar.component.scss']
})
export class ToastBarComponent implements OnInit {

  title: string;
  message: string;
  icon: string;
  type: string;

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: HfToastBarInterface,
              private snackBar: MatSnackBar) {
  }

  ngOnInit(): void {
    this.title = this.data?.title;
    this.message = this.data?.message;
    this.icon = this.data?.icon;
    this.type = this.data?.type;
  }

  dismissToast() {
    this.snackBar.dismiss();
  }
}
