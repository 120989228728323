import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { HFMenuItem } from '../../interfaces/hf-menu-item.interface';

@Component({
  selector: 'hf-dropdown-menu',
  templateUrl: './dropdown-menu.component.html',
  styleUrls: ['./dropdown-menu.component.scss'],
})
export class DropdownMenuComponent implements OnInit {
  @Input() topLabel: string;
  @Input() icon: string;
  @Input() source: HFMenuItem[];
  @Input() hideDivider = false;
  @Input() useAvatar = false;

  constructor(
    public translate: TranslateService,
    private router: Router
    ) {}

  ngOnInit(): void {}

  onClick(item: HFMenuItem): void {
    if (item.target) {
      const url = this.router.serializeUrl(
        this.router.createUrlTree([item.url]));
    
      window.open(url, '_blank');
    } else {
      this.router.navigateByUrl(item.url);
    }
    
  }
}
