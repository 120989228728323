<ng-container [ngSwitch]="styleType">
  <button
    [type]="type"
    *ngSwitchCase="'primary'"
    mat-flat-button
    class="text-icon-btn btn-primary-type"
    [ngClass]="{ 'remove-border-radius': removeBorderRadius }"
    [hfColor]="hfColor"
    [disabled]="disabled"
    [style.width]="fullWidth ? '100%' : width"
    [class.start-alignment]="alignStart"
  >
    <mat-icon *ngIf="matIcon">
      {{ icon }}
    </mat-icon>
    <i *ngIf="!matIcon" class="icon-{{ icon }}"></i>
    <span *ngIf="showText">
      {{ text }}
    </span>
  </button>
  <button
    [type]="type"
    *ngSwitchCase="'secondary'"
    mat-stroked-button
    class="text-icon-btn btn-secondary-type"
    [ngClass]="{ 'remove-border-radius': removeBorderRadius }"
    [hfColor]="hfColor"
    [disabled]="disabled"
    [style.width]="fullWidth ? '100%' : width"
  >
    <mat-icon *ngIf="matIcon">
      {{ icon }}
    </mat-icon>
    <i *ngIf="!matIcon" class="icon-{{ icon }}"></i>
    <span *ngIf="showText">
      {{ text }}
    </span>
  </button>
  <button
    [type]="type"
    *ngSwitchCase="'ghost'"
    mat-button
    class="text-icon-btn btn-ghost-type"
    [ngClass]="{ 'remove-border-radius': removeBorderRadius }"
    [hfColor]="hfColor"
    [disabled]="disabled"
    [style.width]="fullWidth ? '100%' : width"
  >
    <mat-icon *ngIf="matIcon">
      {{ icon }}
    </mat-icon>
    <i *ngIf="!matIcon" class="icon-{{ icon }}"></i>
    <span *ngIf="showText">
      {{ text }}
    </span>
  </button>
</ng-container>
<ng-content select="[mat-menu]"></ng-content>
