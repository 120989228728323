import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'hf-generic-table-pagination',
  templateUrl: './generic-table-pagination.component.html',
  styleUrls: ['./generic-table-pagination.component.scss'],
})
export class GenericTablePaginationComponent implements OnInit {
  @Input() pageSize = 25;
  @Input() page = 0;
  @Input() length = 0;

  @Output() paginationChanged: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();

  constructor() {}

  ngOnInit(): void {}

  public emitValues(event: PageEvent) {
    this.paginationChanged.emit(event);
  }
}
