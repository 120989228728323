<div class="uploadfilecontainer">
  <h4 *ngIf="showTitle">{{ 'IMAGE.UPLOAD_TITLE' | translate }}</h4>
  <div
    class="upload-image"
    [style.background-color]="color"
    [style.padding]="padding"
    (click)="fileInput.click()"
    hfDragDrop
    (onFileDropped)="uploadFile($event)"
    (onTitleChanged)="setTitle($event)"
  >
    <form>
      <input hidden type="file" multiple="multiple" #fileInput (change)="uploadFile($event.target.files); fileInput.value = ''" />
    </form>
    <mat-icon>upload</mat-icon>
    <span>{{ (isMobile ? mobileTitle : title) | translate }}</span>
  </div>
  <span *ngIf="showAllowableFormats">
    Maximum size: {{ fileData.maximumSize }} MB • Format: {{ fileData.allowedFormats }}
  </span>
  <ng-container *ngIf="showUploadedImages">
    <h4 class="subtitle">{{ 'IMAGE.UPLOADED_TITLE' | translate }} ({{ files?.length }})</h4>
    <div class="files-list">
      <ng-container *ngFor="let file of files; let i = index">
        <div class="file-item">
          <mat-icon class="close-icon" (click)="deleteFile(file._id)">close</mat-icon>
          <img [src]="file.url" class="file-item-image" (click)="openImageDialog(i)" />
          <img class="overlay-icon" [src]="'../../../assets/icons/search.svg'" />
        </div>
      </ng-container>
    </div>
  </ng-container>
</div>

<ng-container *ngIf="uploadProgress && uploadProgress > 0">
  <div class="upload-overlay">
    <div class="modal">
      <span class="text-indicator">{{ 'IMAGE.UPLOADING' | translate }}...</span>
      <div class="upload-indicator">
        <span [style.width.%]="uploadProgress">{{ uploadProgress }}%</span>
      </div>
    </div>
  </div>
</ng-container>

<!-- span with file size should be improved to map file data with one more property something like dataInMB -->
