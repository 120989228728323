import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'hf-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {

  @Input() isFormInModal = false;
  @Input() removePadding = false;
  @Input() title = '';
  @Input() subtitle = '';
  @Input() fullHeight = false;
  @Input() icon = '';
  @Input() iconClass = '';
  @Input() tooltip = ''; 
  @Input() showShadows = true;
  @Input() shadowBorder = '';
  constructor() { }

  ngOnInit(): void {
  }

}
