import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import * as Constants from '../../constants';
@Component({
  selector: 'hf-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit, OnDestroy {
  @Input() completedCangePassword: boolean;
  @Input() shouldChangePassword = false;
  @Output() changePasswordEvent: EventEmitter<any> = new EventEmitter();
  changePasswordForm: FormGroup;
  oldPasswordError = '';
  newPasswordError = '';
  matchPasswordError = '';
  numPatternCheck: boolean;
  specialCharPattern: boolean;
  uppercaseCharacterPattern: boolean;
  minLengthPattern: boolean;

  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private fb: FormBuilder) { }

  ngOnInit(): void {
    this.changePasswordForm = this.fb.group({
        oldPassword: new FormControl('', [Validators.required]),
        newPassword: new FormControl('', [Validators.required]),
        repeatPassword: new FormControl('', [Validators.required]),
    });
    this.onChangesChangePasswordForm();

    this.changePasswordForm.get('newPassword').valueChanges.pipe(takeUntil(this.destroy$)).subscribe(val => {
      this.numPatternCheck = Constants.numberPattern.test(val);
      this.specialCharPattern = Constants.specialCharacterPattern.test(val);
      this.minLengthPattern = val && val.length > 7;
      this.uppercaseCharacterPattern = Constants.uppercaseCharacterPattern.test(val);
    })
  }

  onChangesChangePasswordForm() {
    this.changePasswordForm.get('oldPassword').valueChanges.pipe(takeUntil(this.destroy$),debounceTime(300)).subscribe(e => {
      if (this.changePasswordForm.get('oldPassword').hasError('required')) {
        this.oldPasswordError = 'Old password is required';
      } else {
        this.oldPasswordError = '';
      }
    });
    this.changePasswordForm.get('newPassword').valueChanges.pipe(takeUntil(this.destroy$),debounceTime(300)).subscribe(e => {
      if (this.changePasswordForm.get('newPassword').hasError('required')) {
        this.newPasswordError = 'New password is required';
      } else {
        this.newPasswordError = '';
      }
    });
    this.changePasswordForm.get('repeatPassword').valueChanges.pipe(takeUntil(this.destroy$), debounceTime(300)).subscribe(e => {
      if (
        this.changePasswordForm.get('repeatPassword').hasError('required')
        || this.changePasswordForm.get('newPassword').value !== this.changePasswordForm.get('repeatPassword').value
      ) {
        this.matchPasswordError = 'Passwords do not match';
      } else {
        this.matchPasswordError = '';
      }
    });
  }

  submit() {
    if(this.changePasswordForm.valid &&  this.numPatternCheck && this.specialCharPattern && this.minLengthPattern && this.uppercaseCharacterPattern) {
      if(this.changePasswordForm.get('newPassword').value !== this.changePasswordForm.get('repeatPassword').value) {
        this.matchPasswordError = 'Passwords do not match';
      }
      this.changePasswordEvent.emit(this.changePasswordForm.value);
    } else {
      if(this.changePasswordForm.get('oldPassword').hasError('required')) {
        this.oldPasswordError = 'Old password is required';

      }
      if(this.changePasswordForm.get('newPassword').hasError('required')) {
        this.newPasswordError = 'New password is required';

      }
      if(this.changePasswordForm.get('repeatPassword').hasError('required')) {
        this.matchPasswordError = 'Passwords do not match';
      }
    }
  }


  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
