import { StrapiFile } from '../../models/api-interfaces';

export enum GenericTableDefType {
  default = '',
  date = 'DATE',
  num = 'NUMBER',
  label = 'LABEL',
  compare = 'COMPARE',
  icon = 'ICON',
  longdate = 'LONGDATE',
  rate = 'RATE',
  defaultLongDate = 'DEFAULTLONGDATE',
  category = 'CATEGORY',
  link = 'LINK',
  imageURL = 'IMAGE',
}

export interface GenericTableDef {
  property?: string;
  value?: (data?: any) => string | number;
  displayName: string;
  sortable?: boolean;
  headerStyle?: (data?: string | number | boolean | any) => { [id: string]: string };
  dataStyle?: (data?: string | number | boolean | any) => { [id: string]: string };
  valueTransform?: (data: string | number | any) => string | number | any[];
  type?: (data?: any) => GenericTableDefType;
  valueUnit?: string;
  icon?: (data?: any) => string;
  tooltip?: string | string[];
  gridItemSize?: (data?: any) => string;
  showOnMobile?: (data?: any) => boolean;
  href?: (data?: any) => string;
  images?: (data?: any) => StrapiFile[];
}

export interface GenericTableData<T> {
  expanded?: boolean;
  detailData?: T[] | any[];
}

export interface GenericTableOptions {
  page: number;
  pageSize: number;
  totalItems: number;
}
