import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { SideBarComponent } from './side-bar.component';
import { SideBarDirective } from './side-bar.directive';
import { MatSidenavModule } from '@angular/material/sidenav';
import { IconButtonModule } from '../../components/icon-button/icon-button.module';
import { MatListModule } from '@angular/material/list';
import { RouterModule } from '@angular/router';
import { MenuItemModule } from '../../components/menu-item/menu-item.module';
import { ColorModule } from '../../components/colors/color.module';
import { TopToolbarModule } from '../top-toolbar/top-toolbar.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [SideBarComponent, SideBarDirective],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSidenavModule,
    IconButtonModule,
    MatListModule,
    RouterModule,
    MenuItemModule,
    ColorModule,
    TopToolbarModule,
    TranslateModule,
  ],
  exports: [SideBarComponent],
})
export class SideBarModule {}
