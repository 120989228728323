<div class="date-range-picker">
  <mat-form-field appearance="outline">
    <mat-label>{{ pickerLabel }}</mat-label>
    <mat-date-range-input
      [formGroup]="range"
      [rangePicker]="picker"
      [min]="minDate"
      [max]="maxDate"
      [dateFilter]="boundDateFiler"
    >
      <input
        matStartDate
        formControlName="start"
        placeholder="{{ startPlaceholder }}"
        timePicker="true"
        [readonly]="readonly"
        [required]="required"
        time="true"
      />
      <input
        matEndDate
        formControlName="end"
        placeholder="{{ endPlaceholder }}"
        [required]="required"
        [readonly]="readonly"
      />
    </mat-date-range-input>
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker #picker [dateClass]="dateClass" [opened]="opened"></mat-date-range-picker>
  </mat-form-field>
</div>
