import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { QuickActionMenuComponent } from './quick-action-button.component';
import { TextIconButtonModule } from '../text-icon-button/text-icon-button.module';
import { IconButtonModule } from '../icon-button/icon-button.module';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { MatDividerModule } from '@angular/material/divider';
import { ColorModule } from '../colors/color.module';
import { SingleActionComponent } from './single-action/single-action.component';

@NgModule({
  declarations: [QuickActionMenuComponent, SingleActionComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    TextIconButtonModule,
    IconButtonModule,
    MatMenuModule,
    MatIconModule,
    TranslateModule,
    MatDividerModule,
    ColorModule,
  ],
  exports: [QuickActionMenuComponent, SingleActionComponent],
})
export class QuickActionButtonModule {}
