import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ImagePreviewComponent } from './image-preview/image-preview.component';
import { DragDropDirective } from './file-upload.directive';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { IconButtonModule } from '../icon-button/icon-button.module';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { FileUploadComponent } from './file-upload.component';
import { ColorModule } from '../colors/color.module';


@NgModule({
  declarations: [FileUploadComponent, ImagePreviewComponent, DragDropDirective],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatIconModule,
    TranslateModule,
    IconButtonModule,
    MatDialogModule,
    ColorModule,
    MatButtonModule
  ],
  exports: [FileUploadComponent, ImagePreviewComponent]
})
export class FileUploadModule {
}
