import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { HFMenuItem } from 'honeyfield-component-library';

@Component({
  selector: 'hf-single-action',
  templateUrl: './single-action.component.html',
  styleUrls: ['./single-action.component.scss'],
})
export class SingleActionComponent implements OnInit {
  @Input() menuItem: HFMenuItem;
  @Output() openDialog: EventEmitter<HFMenuItem> = new EventEmitter();
  @Output() openSpecificTabAfterRedirect: EventEmitter<number> = new EventEmitter();

  constructor(private router: Router) {}

  ngOnInit(): void {}

  processAction(item: HFMenuItem): void {
    if (item.openDialog && item.openDialog.shouldOpenDialog) {
      this.openDialog.emit(item);
    } else if (item.url) {
      this.router.navigate([item.url]).then(() => {
        if (item.activeTab) {
          this.openSpecificTabAfterRedirect.emit(item.activeTab);
        }
      });
    }
  }
}
