import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PaidStatusEnum, Rental, RentalResponse, ReservationStatus, SodaCalendarElement } from 'honeyfield-shared-library';
import { UtilsService } from 'honeyfield-component-library';

@Injectable({
  providedIn: 'root',
})
export class RentalService {
  private apiEndpoint = `${this.environment.apiEndpoint}/rentals`;

  constructor(private http: HttpClient, @Inject('env') private environment) { }

  public getRentals(query: any = null, pagination: any = null, id?: string): Observable<RentalResponse> {
    let url = this.apiEndpoint;
    const page = pagination && pagination.page ? `&page=${pagination.page}` : '';
    const itemsPerPage = pagination && pagination.itemsPerPage ? `&itemsPerPage=${pagination.itemsPerPage}` : '';

    if (query) {
      const sort = query.sort;
      delete query.sort;
      const order = query.order ?? 1;
      delete query.order;
      if (query && query.status === ReservationStatus.All) {
        query.status = null;
      }
      url = UtilsService.createUrlFromParams(url, query);
      url = `${url}sort=${sort}:${order}${itemsPerPage}${page}`;
    } else if (id) {
      url = `${this.apiEndpoint}?guest=${id}&sort=arrival:1${itemsPerPage}${page}`;
    } else {
      url = `${url}?sort=arrival:1${itemsPerPage}${page}`;
    }

    return this.http.get<RentalResponse>(url);
  }

  public getRentalById(rentalId): Observable<Rental> {
    const url = `${this.apiEndpoint}/${rentalId}`;
    return this.http.get<Rental>(url);
  }

  public getRentalsCount(query: any = null, id?: string): Observable<number> {
    let url = `${this.apiEndpoint}/count`;

    if (query) {
      const sort = query.sort;
      delete query.sort;
      url = UtilsService.createUrlFromParams(url, query);
      url = `${url}_sort=${sort}:asc`;
    } else if (id) {
      url = `${url}?guest=${id}`;
    } else {
      url = `${url}?_sort=arrival:asc`;
    }

    return this.http.get<number>(url);
  }

  public createRental(rental: Rental): Observable<Rental> {
    const url = `${this.apiEndpoint}/createRental`;
    const body = { rental };
    return this.http.post<Rental>(url, body);
  }

  public updateRental(rentalId: string, rental: Rental): Observable<Rental> {
    const url = `${this.apiEndpoint}/updateRental/${rentalId}`;
    const body = { rental };
    return this.http.put<Rental>(url, body);
  }

  public deleteRental(rentalId: string): Observable<any> {
    const url = `${this.apiEndpoint}/deleteRental/${rentalId}`;
    return this.http.delete<any>(url);
  }

  public sendConfirmation(rentalId: string): Observable<any> {
    const url = `${this.apiEndpoint}/send_confirmation`;
    const body = { rentalId };
    return this.http.post<Rental>(url, body);
  }

  public downloadConfirmation(rentalId: string): Observable<Blob> {
    const url = `${this.apiEndpoint}/download_confirmation`;
    const body = { rentalId };
    return this.http.post(url, body, { responseType: 'blob' });
  }

  public sendInvoice(rentalId: string): Observable<any> {
    const url = `${this.apiEndpoint}/send-invoice`;
    const body = { rentalId };
    return this.http.post<Rental>(url, body);
  }

  public getCalendarRentals(query: any = null): Observable<SodaCalendarElement[]> {
    let url = `${this.apiEndpoint}/calendar-rentals`;
    if (query) {
      url = UtilsService.createUrlFromParams(url, query);
    }
    return this.http.get<SodaCalendarElement[]>(url);
  }

  updateStatus(rentalId: string, status: string): Observable<any> {
    const url = `${this.apiEndpoint}/status/${rentalId}`;
    return this.http.put<any>(url, { status });
  }

  updatePaidStatus(rentalId: string, status: PaidStatusEnum): Observable<boolean> {
    const url = `${this.apiEndpoint}/paid/status/${rentalId}`;
    const body = { paidStatus: status };
    return this.http.put<boolean>(url, body);
  }
}
