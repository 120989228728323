import { Directive, ElementRef, Input, AfterViewInit, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[hfColor]'
})
export class ColorDirective implements AfterViewInit, OnChanges {

  @Input() hfColor: string;

  constructor(private element: ElementRef) { }

  ngAfterViewInit(): void {
    this.element.nativeElement.classList.add(this.hfColor);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.element.nativeElement.classList.add(this.hfColor);
  }

}
