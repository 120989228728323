import { Component, ContentChild, Input, OnChanges, OnInit, Output, TemplateRef } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { Subject } from 'rxjs';
import { GenericTableData, GenericTableDef } from '../table-data.dto';
import { GenericTableGridConfig, GenericTableService } from '../generic-table.service';
import { UtilsService } from '../../../services/utils.service';
import { EndColumnsInfo, StartColumnsInfo } from '../../../models/api-interfaces';

@Component({
  selector: 'hf-generic-table-header',
  templateUrl: './generic-table-header.component.html',
  styleUrls: ['./generic-table-header.component.scss'],
})
export class GenericTableHeaderComponent<T extends GenericTableData<T>> implements OnInit, OnChanges {
  @Input() selectable = false;
  @Input() public showActions = true;
  @Input() public showStart = true;

  @Input() set columns(val: GenericTableDef[]) {
    this.columnData = val;

    const grindConfig: GenericTableGridConfig = {
      showActions: this.showActions,
      showStart: this.showStart,
      selectable: this.selectable,
    };

    this.gridColumnTemplate = this.genericTableService.createGridTemplate(
      grindConfig,
      this.columnData,
      this.startColumnsInfo,
      this.endColumnsInfo
    );
  }

  private _tableData: T[];
  @Input()
  public set tableData(value: T[]) {
    this._tableData = value;
  }

  public get tableData(): T[] {
    return this._tableData;
  }

  public columnData: GenericTableDef[];

  @Input()
  public selection: SelectionModel<T>;

  @Output()
  public selectionEmitter: Subject<SelectionModel<T>> = new Subject<SelectionModel<T>>();

  @Input() startColumnsInfo: StartColumnsInfo[];
  @Input() endColumnsInfo: EndColumnsInfo[];
  @Input() xScrollable = false;

  public gridColumnTemplate = '';

  public isMobile = false;

  @ContentChild('start', { static: false }) startTemplateRef: TemplateRef<any>;
  @ContentChild('end', { static: false }) endTemplateRef: TemplateRef<any>;

  constructor(private utilsService: UtilsService, private genericTableService: GenericTableService) {}

  ngOnInit(): void {
    this.isMobile = this.utilsService.isMobile;
    if (!this.xScrollable) {
      this.xScrollable = this.isMobile;
    }
  }

  ngOnChanges() {
    const grindConfig: GenericTableGridConfig = {
      showActions: this.showActions,
      showStart: this.showStart,
      selectable: this.selectable,
    };

    this.gridColumnTemplate = this.genericTableService.createGridTemplate(
      grindConfig,
      this.columnData,
      this.startColumnsInfo,
      this.endColumnsInfo
    );
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.tableData.length;
    return numSelected === numRows;
  }

  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
    } else {
      this.tableData.forEach((row) => this.selection.select(row));
    }

    this.selectionEmitter.next(this.selection);
  }

  getStyle(column: GenericTableDef) {
    if (column.headerStyle) {
      return column.headerStyle();
    }

    return null;
  }
}
