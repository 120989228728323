import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { DateTimePickerComponent } from './date-time-picker.component';
import { DatePickerModule } from '../date-picker/date-picker.module';
import { SelectModule } from '../select/select.module';
import { TranslateService } from '@ngx-translate/core';


@NgModule({
  declarations: [DateTimePickerComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    DatePickerModule,
    SelectModule
  ],
  exports: [DateTimePickerComponent]
})
export class DateTimePickerModule {
}
