import { NgModule } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LayoutModule } from '@angular/cdk/layout';
import { RouterModule } from '@angular/router';
import { CardModule } from './components/card/card.module';
import { MatListModule } from '@angular/material/list';
import { MatNativeDateModule } from '@angular/material/core';
import { TranslateLoader, TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { GenericTableModule } from './building-blocks/generic-table/generic-table.module';
import { PasswordStrengthMeterModule } from 'angular-password-strength-meter';
import { CheckboxModule } from './components/checkbox/checkbox.module';
import { DatePickerModule } from './components/date-picker/date-picker.module';
import { IconButtonModule } from './components/icon-button/icon-button.module';
import { InputTextModule } from './components/input-text/input-text.module';
import { MenuItemModule } from './components/menu-item/menu-item.module';
import { PillowModule } from './components/pillow/pillow.module';
import { PlaceholderModule } from './components/placeholder/placeholder.module';
import { RadioButtonModule } from './components/radio/radio-button.module';
import { SearchModule } from './components/search/search.module';
import { SelectModule } from './components/select/select.module';
import { SlideToggleModule } from './components/slide-toggle/slide-toggle.module';
import { SliderModule } from './components/slider/slider.module';
import { TextButtonModule } from './components/text-button/text-button.module';
import { TextIconButtonModule } from './components/text-icon-button/text-icon-button.module';
import { ToastBarModule } from './components/toast-bar/toast-bar.module';
import { TopDateModule } from './components/top-date/top-date.module';
import { ConfirmationDialogModule } from './components/confirmation-dialog/confirmation-dialog.module';
import { DateTimePickerModule } from './components/date-time-picker/date-time-picker.module';
import { DropdownMenuModule } from './components/dropdown-menu/dropdown-menu.module';
import { FileUploadModule } from './components/file-upload/file-upload.module';
import { QuickActionButtonModule } from './components/quick-action-button/quick-action-button.module';
import { ChangePasswordModule } from './building-blocks/change-password/change-password.module';
import { LoginRegisterModule } from './building-blocks/login-register/login-register.module';
import { OnboardingListElementModule } from './building-blocks/onboarding-list-element/onboarding-list-element.module';
import { TopToolbarModule } from './building-blocks/top-toolbar/top-toolbar.module';
import { ColorModule } from './components/colors/color.module';
import { SideBarModule } from './building-blocks/side-bar/side-bar.module';
import { PillowFilterModule } from './components/pillow-filter/pillow-filter.module';
import { MatSelectModule } from '@angular/material/select';
import { GroupSelectModule } from './components/group-select/group-select.module';
import { DateRangePickerModule } from './components/date-range-picker/date-range-picker.module';
import { UserAvatarModule } from './components/user-avatar/user-avatar.module';
import localeDe from '@angular/common/locales/de';
import { LoginRegisterV2Module } from './building-blocks/login-register-v2/login-register-v2.module';

// eslint-disable-next-line func-style, prefer-arrow/prefer-arrow-functions
export function httpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

registerLocaleData(localeDe);

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    LayoutModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient],
      },
      defaultLanguage: 'en',
    }),
    GenericTableModule,
    CardModule,
    PasswordStrengthMeterModule,
    CheckboxModule,
    DatePickerModule,
    IconButtonModule,
    ColorModule,
    InputTextModule,
    MenuItemModule,
    PillowModule,
    PillowFilterModule,
    PlaceholderModule,
    RadioButtonModule,
    SearchModule,
    SelectModule,
    SlideToggleModule,
    SliderModule,
    TextButtonModule,
    TextIconButtonModule,
    ToastBarModule,
    TopDateModule,
    ConfirmationDialogModule,
    DateTimePickerModule,
    DropdownMenuModule,
    FileUploadModule,
    QuickActionButtonModule,
    ChangePasswordModule,
    LoginRegisterModule,
    OnboardingListElementModule,
    TopToolbarModule,
    SideBarModule,
    MatSelectModule,
    GroupSelectModule,
    DateRangePickerModule,
    UserAvatarModule,
    LoginRegisterV2Module
  ],
  exports: [
    CardModule,
    MatSnackBarModule,
    MatProgressBarModule,
    MatListModule,
    GenericTableModule,
    PasswordStrengthMeterModule,
  ],
  providers: [MatNativeDateModule, TranslatePipe],
})
export class UiLibraryModule {}
