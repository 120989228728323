import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HFValidationConfig } from '../../interfaces/hf-validation-config.interface';

@Component({
  selector: 'hf-login-register-v2',
  templateUrl: './login-register-v2.component.html',
  styleUrls: ['./login-register-v2.component.scss'],
})
export class LoginRegisterV2Component implements OnInit {
  @Input() logo: string;
  @Input() headerLogo: boolean;
  @Input() completedResetPassword: boolean;
  @Input() validationConfig: HFValidationConfig;
  @Input() openRegister: boolean;
  @Input() showTC = false;
  @Input() recaptchaConfirmation = false;
  @Input() hideRecaptcha = false;
  @Input() showShadows = true;
  @Input() registerTitleColor: string;
  @Input() privacyPolicyURL: string;
  @Input() termsAndConditionsURL: string;

  @Output() eventLogin: EventEmitter<any> = new EventEmitter();
  @Output() eventLoginViaGoogle: EventEmitter<any> = new EventEmitter();
  @Output() eventRegister: EventEmitter<any> = new EventEmitter();
  @Output() eventForgotPassword: EventEmitter<any> = new EventEmitter();
  @Output() eventResetPassword: EventEmitter<any> = new EventEmitter();
  login = true;
  selectedIndex = 0;

  constructor(public translate: TranslateService) {}

  ngOnInit(): void {
    this.selectedIndex = this.openRegister ? 1 : 0;
  }

  setActiveTab(index) {
    this.selectedIndex = index;
  }

  submitForm(event) {
    if (event[0] === 'login') {
      this.eventLogin.emit(event[1]);
    } else if (event[0] === 'register') {
      this.eventRegister.emit(event[1]);
    }
  }

  toggleForm(navigateTo: string) {
    if (navigateTo === 'login') {
      this.login = true;
      this.selectedIndex = 0;
    }
  }

  toggleForrgotPassword() {
    this.login = false;
  }

  initiateForgotPassword(event) {
    this.eventForgotPassword.emit([event]);
  }

  resetPassword(event) {
    this.eventResetPassword.emit([event]);
  }

  submitLoginViaGoogle() {
    this.eventLoginViaGoogle.emit();
  }
}
