import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { User } from 'honeyfield-shared-library';
import { debounceTime, Subject, takeUntil } from 'rxjs';
import { MessageBox, MessageBoxEnum } from './components/messages-box/messages-box.component';
import { LeaveReviewModalComponent } from './modals/leave-review-modal/leave-review-modal.component';
import { MessageDto } from './models/message.dto';
import { Router } from '@angular/router';

@Component({
  selector: 'hf-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
})
export class ChatComponent implements OnInit, OnDestroy {
  @ViewChild('content') private content: ElementRef;
  public searchInput: FormControl = new FormControl('');
  @Input() currentUser: User;
  @Input() apiPagination = false;
  @Input() itemsPerPage = 5;
  @Input() totalItems;
  @Input() inAccount = false;
  @Input() inAdmin = false;
  @Input() isMobile = false;
  @Input() attachments = false;
  @Input() disablePagination = false;

  _groupedMessages: [];
  _users: [];
  filteredUsers = [];

  message: FormControl = new FormControl('');
  public messageType = MessageBoxEnum;

  @Input() set groupedMessages(value: []) {
    this._groupedMessages = value;
    this.scrollToElement();

    // read messages
    if (this.receiver) this.readMessagesBySenderEvent.emit({ senderId: this.receiver.id });
  }

  @Input() set defaultReceiver(value: User) {
    this.receiver = value;
  }

  @Input() set users(value: []) {
    if (!value) return;
    this._users = value;
    this.filteredUsers = this._users.slice(0, this.itemsPerPage);
  }

  @Output() usersSearchChanged = new EventEmitter<number>();
  @Output() pageChanged = new EventEmitter<{ currentPage: number; prevPage: number; itemsPerPage: number }>();
  @Output() openMessages = new EventEmitter<{ receiverId: string }>();
  @Output() sendMessageEvent = new EventEmitter<MessageDto>();
  @Output() readMessagesBySenderEvent = new EventEmitter<{ senderId: string }>();
  @Output() showMobileMessagesEvent = new EventEmitter<boolean>();

  currentPage = 1;
  currentFile = null;
  receiver: User = null;
  public hideUserInfo = false;
  public showMobileMessages = true;
  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitzer: DomSanitizer,
    private dialog: MatDialog,
    private router: Router
  ) {
    this.registerSVGIcons();
  }

  ngOnInit(): void {
    this.searchInput.valueChanges
      .pipe(takeUntil(this.destroy$), debounceTime(300))
      .subscribe((data) => this.usersSearchChanged.emit(data));
  }

  registerSVGIcons() {
    this.matIconRegistry.addSvgIcon(
      'hf-left-arrow',
      this.domSanitzer.bypassSecurityTrustResourceUrl('assets/left.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'hf-right-arrow',
      this.domSanitzer.bypassSecurityTrustResourceUrl('assets/right.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'hf-double-right-arrow',
      this.domSanitzer.bypassSecurityTrustResourceUrl('assets/double_right.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'hf-double-left-arrow',
      this.domSanitzer.bypassSecurityTrustResourceUrl('assets/double_left.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'hf-checkmark',
      this.domSanitzer.bypassSecurityTrustResourceUrl('assets/Checkmark.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'hf-questionmark',
      this.domSanitzer.bypassSecurityTrustResourceUrl('assets/Questionmark.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'hf-icon-reminder',
      this.domSanitzer.bypassSecurityTrustResourceUrl('assets/icon-reminder.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'hf-icon-feedback',
      this.domSanitzer.bypassSecurityTrustResourceUrl('assets/icon_feedback.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'hf-icon-declined',
      this.domSanitzer.bypassSecurityTrustResourceUrl('assets/icon_declined.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'hf-checkmark',
      this.domSanitzer.bypassSecurityTrustResourceUrl('assets/Checkmark.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'hf-checkmark',
      this.domSanitzer.bypassSecurityTrustResourceUrl('assets/Checkmark.svg')
    );

    this.matIconRegistry.addSvgIcon('hf-star', this.domSanitzer.bypassSecurityTrustResourceUrl('assets/star.svg'));
  }

  currentPageChange(page) {
    if (this.apiPagination) {
      return this.pageChanged.emit({
        prevPage: this.currentPage,
        currentPage: page,
        itemsPerPage: this.itemsPerPage,
      });
    }

    const start = (page - 1) * this.itemsPerPage;

    const end = start + this.itemsPerPage;
    this.currentPage = page;

    this.filteredUsers = this._users.slice(start, end);
  }

  trackByFn(index, item) {
    return item.id;
  }

  eventClicked(messageBox: MessageBox, requestId?: any) {
    if (messageBox.type === MessageBoxEnum.rentCompleted) {
      this.leaveFeedback();
    } else if (messageBox.type === MessageBoxEnum.requestConfirmed) {
      this.router.navigate(['profile', 'rentings', requestId]);
    }
  }

  sendMessage() {
    if (!this.message.value && !this.currentFile) return;

    this.sendMessageEvent.emit({
      file: this.currentFile,
      message: this.message.value,
      receiver: {
        id: this.receiver?.id,
      },
      type: MessageBoxEnum.message,
    });
    this.currentFile = null;
    this.message.patchValue('');
  }

  scrollToElement(): void {
    if (!this.content) return;

    setTimeout(() => {
      if (this.isMobile) {
        window.scrollTo(0, document.body.scrollHeight);
      } else {
        this.content.nativeElement.scroll({
          top: this.content.nativeElement.scrollHeight,
          left: 0,
        });
      }
    });
  }

  // onScroll(event: any) {
  // if (this.receiver && event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
  //   this.readMessagesBySenderEvent.emit({ senderId: this.receiver.id });
  // }
  // }

  // checkIfScrollIsActive() {
  // setTimeout(() => {
  //   if (
  //     this.receiver &&
  //     this.content &&
  //     this.content.nativeElement.scrollHeight === this.content.nativeElement.clientHeight
  //   ) {
  //     console.log(this.receiver)
  //     this.readMessagesBySenderEvent.emit({ senderId: this.receiver.id });
  //   }
  // }, 0);
  // }

  processFile(file) {
    this.currentFile = file;
  }

  leaveFeedback() {
    const dialogRef = this.dialog.open(LeaveReviewModalComponent, {
      width: '400px',
      data: {},
      panelClass: 'custom-dialog-container',
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }

  openMessagesByUser(user) {
    this.receiver = user;
    this.openMessages.emit({ receiverId: user.id });
    if (this.isMobile) {
      this.showMobileMessages = false;
	    this.showMobileMessagesEvent.emit(this.showMobileMessages);
    }
  }

  onShowMobileMessages() {
    this.showMobileMessages = true;
    this.showMobileMessagesEvent.emit(this.showMobileMessages);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
