import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UtilsService } from 'honeyfield-component-library';
import { PriceCalculation, Pricing, PricingConfig } from 'honeyfield-shared-library';

@Injectable({
  providedIn: 'root',
})
export class PricingService {
  private apiUrl = `${this.environment.apiEndpoint}/price-schemas`;

  private apiConfigUrl = `${this.environment.apiEndpoint}/price-schema-configs`;

  constructor(private http: HttpClient, @Inject('env') private environment) {}

  public getPricings(query: any = null): Observable<Pricing[]> {
    let url = `${this.apiUrl}`;
    if (query) {
      url = UtilsService.createUrlFromParams(url, query);
    }
    return this.http.get<Pricing[]>(url);
  }

  public getPricing(id: string): Observable<Pricing> {
    const url = `${this.apiUrl}/${id}`;
    return this.http.get<Pricing>(url);
  }

  public add(pricing: Pricing): Observable<Pricing> {
    return this.http.post<Pricing>(this.apiUrl, pricing);
  }

  public update(pricing: Pricing): Observable<Pricing> {
    const url = `${this.apiUrl}/${pricing.id}`;
    return this.http.put<Pricing>(url, pricing);
  }

  public delete(pricing: Pricing): Observable<Pricing> {
    const url = `${this.apiUrl}/${pricing.id}`;
    return this.http.delete<Pricing>(url);
  }

  public deletePricing(id: string): Observable<Pricing> {
    return this.http.delete<Pricing>(`${this.apiUrl}/${id}`);
  }

  public addPricingConfig(config: PricingConfig) {
    return this.http.post<PricingConfig>(this.apiConfigUrl, config);
  }

  public deletePricingConfig(config: PricingConfig) {
    const url = `${this.apiConfigUrl}/${config.id}`;
    return this.http.delete<PricingConfig>(url);
  }

  public updatePricingConfig(config: PricingConfig) {
    const url = `${this.apiConfigUrl}/${config.id}`;
    return this.http.put<PricingConfig>(url, config);
  }

  public calculatePrice(query: any = null): Observable<PriceCalculation> {
    const url = `${this.environment.apiEndpoint}/pricings/calculate`;
    return this.http.post<PriceCalculation>(url, query);
  }
}
