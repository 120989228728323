import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { PillowFilter, QueryParamsSearch } from '../../models/api-interfaces';

@Component({
  selector: 'hf-pillow-filter',
  templateUrl: './pillow-filter.component.html',
  styleUrls: ['./pillow-filter.component.scss'],
})
export class PillowFilterComponent implements OnInit {
  @Input() filters: PillowFilter[] = [
    { icon: 'place', label: 'Add Location', value: 'Wien, Austria' },
    { icon: 'calendar_today', label: 'Date Range' },
    { label: 'Anbieter' },
    { label: 'Kategorie' },
    { label: 'Longer Title Lorme ipsum' },
    { icon: 'filter_list', label: 'Filters' }
  ];

  @Input() showBreakLine = true;
  @Input() showLowestPrice = true;

  _activeFilter: QueryParamsSearch;
  get activeFilter(): QueryParamsSearch {
    return this._activeFilter;
  }
  @Input() set activeFilter(value: QueryParamsSearch) {
    this._activeFilter = value;
    this.filters[0].value = value?.location;
  }
  constructor(public translate: TranslateService, private router: Router) {}

  ngOnInit(): void {
  }

  clear(): void {
    this.activeFilter = null;
  }
}
