<hf-text-icon-button
  *ngIf="!isMobile"
  class="action-button"
  [matMenuTriggerFor]="beforeMenu"
  styleType="primary"
  hfColor="primary"
  icon="add"
  [text]="text"
></hf-text-icon-button>

<hf-icon-button
  *ngIf="isMobile"
  class="action-button-mobile"
  [matMenuTriggerFor]="beforeMenu"
  #t="matMenuTrigger"
  styleType="primary"
  hfColor="primary"
  icon="{{ t.menuOpen ? 'close' : 'add' }}"
></hf-icon-button>

<mat-menu class="custom-mat-menu" #beforeMenu="matMenu" xPosition="before">
  <!-- Rooms -->
  <span class="section" *ngIf="roomsEnabled && roomMenuItems && roomMenuItems.length">
    {{ 'GENERAL.ROOMS' | translate }}
  </span>
  <div *ngFor="let item of roomMenuItems; let i = index">
    <hf-single-action [menuItem]="item" (openDialog)="openDialog.emit(item)"></hf-single-action>
  </div>
  <!-- Items -->
  <span class="section" *ngIf="itemsEnabled && itemMenuItems && itemMenuItems.length">
    {{ 'GENERAL.ITEMS' | translate }}
  </span>
  <div *ngFor="let item of itemMenuItems; let i = index">
    <hf-single-action [menuItem]="item" (openDialog)="openDialog.emit(item)"></hf-single-action>
  </div>
  <!-- More -->
  <span class="section" *ngIf="otherMenuItems && otherMenuItems.length">{{ 'GENERAL.MORE' | translate }}</span>
  <div *ngFor="let item of otherMenuItems; let i = index">
    <hf-single-action
      [menuItem]="item"
      (openDialog)="openDialog.emit(item)"
      (openSpecificTabAfterRedirect)="openSpecificTabAfterRedirect.emit(item?.activeTab)"
    ></hf-single-action>
  </div>
</mat-menu>
