<div class="select-holder">
  <mat-form-field appearance="outline">
    <mat-label>
      {{ placeholder }}
    </mat-label>
    <mat-select
      disableOptionCentering
      [panelClass]="isMultiple ? 'mat-multiselect-custom-position' : 'mat-select-custom-position'"
      #selection
      matNativeControl
      [formControl]="selectInput"
      (valueChange)="doInput(selection.value)"
      [required]="required"
      [multiple]="isMultiple"
      (selectionChange)="onSelection($event)"
    >
      <mat-option
        *ngFor="let item of source; let i = index; let last = last"
        [id]="'option' + i"
        [value]="item.value"
        [ngClass]="highlightLastItem ? { 'highlight-last': last } : null"
        [disabled]="
          item.disabled ||
          (max !== null && this.selectInput.value.length >= max && !this.selectInput.value.includes(item.value))
        "
        [class.opacity]="item.disabled && !showDisabledMessage"
      >
        <div [ngStyle]="{ 'justify-content': textAlignment }" class="option" [class.editable-option]="showEditIcon">
          <span>{{ item.label | translate }}&nbsp;</span>
          <span [ngStyle]="{ color: additionalTextColor }">
            {{ item.additionalText ? ' ' + item.additionalText : '' }}
          </span>
          <span *ngIf="item.disabled && showDisabledMessage" class="not-available">{{ 'GENERAL.NOT_AVAILABLE' | translate }}</span>
        </div>
      </mat-option>
    </mat-select>
    <mat-icon *ngIf="icon" matSuffix>
      {{ icon }}
    </mat-icon>
    <mat-hint class="input-error mt-5" [id]="null" *ngIf="error">
      {{ error | translate }}
    </mat-hint>
  </mat-form-field>
  <mat-icon class="edit-icon" [class.disabled]="!this.selectInput.value" 
  *ngIf="showEditIcon" (click)="onEditClick(this.selectInput)" [matTooltip]="editTooltip">
    edit
  </mat-icon>
</div>
