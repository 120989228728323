<div class="auth-v2-container">
  <div class="switch" *ngIf="login">
    <div [class.active]="selectedIndex === 0" (click)="setActiveTab(0)">
      {{'LOGIN.LOGIN' | translate}}
    </div>
    <div [class.active]="selectedIndex === 1" class="" (click)="setActiveTab(1)">
      {{'LOGIN.REGISTER' | translate}}
    </div>
  </div>
  <div class="content">
    <ng-container *ngIf="selectedIndex === 0; else registrationContainer">
      <ng-container *ngIf="login; else forgotPassword">
        <hf-login-v2 [showShadows]="showShadows" [registerTitleColor]="registerTitleColor"
                     (submitLogin)="submitForm($event)" (loginViaGoogle)="submitLoginViaGoogle()"
                     (toggleForm)="toggleForm('register')"
                     (toggleForgotPassword)="toggleForrgotPassword()"></hf-login-v2>
      </ng-container>
      <ng-template #forgotPassword>
        <hf-forgot-password-v2 [completedResetPassword]="completedResetPassword" (toggleLogin)="toggleForm('login')"
                               (initiateForgotPassword)="initiateForgotPassword($event)"
                               (resetPassword)="resetPassword($event)">
        </hf-forgot-password-v2>
      </ng-template>
    </ng-container>
    <ng-template #registrationContainer>
      <hf-registration-v2 [hideRecaptcha]="hideRecaptcha" [showShadows]="showShadows"
                          (submitRegister)="submitForm($event)" (toggleForm)="toggleForm('login')" [showTC]="showTC"
                          [recaptchaConfirmation]="recaptchaConfirmation" [privacyPolicyURL]="privacyPolicyURL"
                          [termsAndConditionsURL]="termsAndConditionsURL">
        <ng-content></ng-content>
      </hf-registration-v2>
    </ng-template>
  </div>
</div>
