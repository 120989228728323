import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TopToolbarComponent } from './top-toolbar.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatToolbarModule } from '@angular/material/toolbar';
import { IconButtonModule } from '../../components/icon-button/icon-button.module';
import { TopDateModule } from '../../components/top-date/top-date.module';
import { DropdownMenuModule } from '../../components/dropdown-menu/dropdown-menu.module';


@NgModule({
  declarations: [TopToolbarComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatToolbarModule,
    IconButtonModule,
    TopDateModule,
    DropdownMenuModule
  ],
  exports: [TopToolbarComponent]
})
export class TopToolbarModule {
}
