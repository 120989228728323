<div [ngClass]="{'card' : !isFormInModal}" [style.padding]="isFormInModal || removePadding ? '1px 0' : '2rem'" [class.full-height]="fullHeight" [class.shadow]="showShadows && !isFormInModal" class="border-{{shadowBorder}}">
  <div class="title-container" [ngClass]="{'title': title?.length}"><h4 *ngIf="title?.length">{{title}}</h4><mat-icon *ngIf="tooltip?.length" [ngClass]="iconClass?.length ? iconClass : 'info-icon'" [matTooltip]="tooltip?.length ? tooltip : 'Test'" matTooltipPosition="above">info</mat-icon></div>
  <h5 *ngIf="subtitle?.length" class="subtitle">{{subtitle}}</h5>
  <div class="card__content">
    <ng-content></ng-content>
  </div>
<!--  <div class="card__actions">-->
<!--    <ng-content select="[role=actions]"></ng-content>-->
<!--  </div>-->
</div>

