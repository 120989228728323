import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'hf-leave-review-modal',
  templateUrl: './leave-review-modal.component.html',
  styleUrls: ['./leave-review-modal.component.scss'],
})
export class LeaveReviewModalComponent implements OnInit {
  public color = 'primary';
  public text: FormControl = new FormControl('');

  @Output() closeModal = new EventEmitter<string>();

  constructor(public dialogRef: MatDialogRef<LeaveReviewModalComponent>, @Inject(MAT_DIALOG_DATA) public data) {}

  performAction(response: boolean): void {
    this.dialogRef.close(response);
  }

  ngOnInit() {
    if (!this.data) {
      this.data = {
        title: 'Hi',
        text: 'Are you sure that you want to proceed?',
        cancelActionText: 'No',
        confirmActionText: 'Yes',
      };
    }

    if (this.data?.color) this.color = this.data.color;
  }

  close(submit = false) {
    if (submit && !this.text.value) return;

    if (!submit) return this.dialogRef.close();

    this.dialogRef.close(this.text.value);
  }
}
