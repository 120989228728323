import { Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
  selector: 'hf-icon-button',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.scss'],
})
export class IconButtonComponent implements OnInit {
  @HostBinding('style.pointer-events') get pEvents(): string {
    if (this.disabled) {
      return 'none';
    }
    return 'auto';
  }

  @Input() hfColor: string;
  @Input() icon: string;
  @Input() iconUrl: string;
  @Input() tooltipContent: string;
  @Input() disabled: boolean;
  @Input() width: string;
  @Input() height: string;
  @Input() borderRadius: string;
  @Input() iconResize = false;
  @Input() styleType: 'primary' | 'secondary' | 'ghost' = 'primary';
  @Input() type = 'submit';

  constructor() { }

  ngOnInit(): void { }
}
