import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { HFValidationConfig } from '../../interfaces/hf-validation-config.interface';

@Component({
  selector: 'hf-login-register',
  templateUrl: './login-register.component.html',
  styleUrls: ['./login-register.component.scss'],
})
export class LoginRegisterComponent implements OnInit {
  @Input() logo: string;
  @Input() headerLogo: boolean;
  @Input() completedResetPassword: boolean;
  @Input() validationConfig: HFValidationConfig;
  @Input() openRegister: boolean;
  @Input() showTC = false;
  @Input() recaptchaConfirmation = false;
  @Input() hideRecaptcha = false;
  @Input() showShadows = true;
  @Input() registerTitleColor: string;
  @Input() useRouting = false;

  @Output() eventLogin: EventEmitter<any> = new EventEmitter();
  @Output() eventLoginViaGoogle: EventEmitter<any> = new EventEmitter();
  @Output() eventRegister: EventEmitter<any> = new EventEmitter();
  @Output() eventForgotPassword: EventEmitter<any> = new EventEmitter();
  @Output() eventResetPassword: EventEmitter<any> = new EventEmitter();
  login = true;
  forgotPassword = false;

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.login = !this.openRegister;
  }

  submitForm(event) {
    if (event[0] === 'login') {
      this.eventLogin.emit(event[1]);
    } else if (event[0] === 'register') {
      this.eventRegister.emit(event[1]);
    }
  }

  toggleForm(navigateTo: string) {
    if (navigateTo === 'login') {
      if(this.useRouting) {
        this.router.navigate(['login']);
      }else {
        this.login = true;
      }
    } else if (navigateTo === 'register') {
      if(this.useRouting) {
        this.router.navigate(['registration']) ;
      }else {
        this.login = false;
      }
    }
    this.forgotPassword = false;
  }

  toggleForrgotPassword() {
    this.forgotPassword = true;
  }

  initiateForgotPassword(event) {
    this.eventForgotPassword.emit([event]);
  }

  resetPassword(event) {
    this.eventResetPassword.emit([event]);
  }

  submitLoginViaGoogle() {
    this.eventLoginViaGoogle.emit();
  }
}
