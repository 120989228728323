import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ChangePasswordComponent } from './change-password.component';
import { MatCardModule } from '@angular/material/card';
import { TranslateModule } from '@ngx-translate/core';
import { InputTextModule } from '../../components/input-text/input-text.module';
import { MatIconModule } from '@angular/material/icon';
import { PasswordStrengthMeterModule } from 'angular-password-strength-meter';
import { TextButtonModule } from '../../components/text-button/text-button.module';
import { ColorModule } from '../../components/colors/color.module';


@NgModule({
  declarations: [ChangePasswordComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatCardModule,
    TranslateModule,
    InputTextModule,
    MatIconModule,
    PasswordStrengthMeterModule,
    TextButtonModule,
    ColorModule
  ],
  exports: [ChangePasswordComponent]
})
export class ChangePasswordModule {
}
