<mat-label *ngIf="!useAvatar; else avatar" class="top-label" [matMenuTriggerFor]="menu">
  {{ topLabel }}
  <mat-icon>
    {{ icon }}
  </mat-icon>
</mat-label>
<ng-template #avatar>
  <div class="avatar-wrapper">
    <hf-user-avatar
      [matMenuTriggerFor]="menu"
      [name]="topLabel"
      cursorStyle="pointer"
      color="var(--sidebar-background-color)"></hf-user-avatar>
  </div>
</ng-template>
<mat-menu class="hf-dropdown-menu" #menu="matMenu">
  <div *ngFor="let item of source; let i = index">
    <button mat-menu-item (click)="onClick(item)">
      <mat-icon *ngIf="item.iconLeft">
        {{ item.iconLeft }}
      </mat-icon>
      <span>
        {{ item.label | translate }}
      </span>
    </button>
    <mat-divider class="custom-divider" *ngIf="!hideDivider && i !== source.length - 1"></mat-divider>
  </div>
</mat-menu>
