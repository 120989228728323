<div *ngIf="logo" [ngClass]="{ 'logo-container': !headerLogo, 'logo-container-header': headerLogo }">
  <img [src]="logo" />
</div>

<hf-login-form
  *ngIf="login && !forgotPassword"
  [showShadows]="showShadows"
  [registerTitleColor]="registerTitleColor"
  (submitLogin)="submitForm($event)"
  (loginViaGoogle)="submitLoginViaGoogle()"
  (toggleForm)="toggleForm('register')"
  (toggleForgotPassword)="toggleForrgotPassword()"
></hf-login-form>

<hf-register-form
  [hideRecaptcha]="hideRecaptcha"
  *ngIf="!login && !forgotPassword"
  [showShadows]="showShadows"
  (submitRegister)="submitForm($event)"
  (toggleForm)="toggleForm('login')"
  [showTC]="showTC"
  [recaptchaConfirmation]="recaptchaConfirmation"
>
  <ng-content></ng-content>
</hf-register-form>

<hf-forgot-password
  [completedResetPassword]="completedResetPassword"
  (toggleLogin)="toggleForm('login')"
  (initiateForgotPassword)="initiateForgotPassword($event)"
  (resetPassword)="resetPassword($event)"
  *ngIf="forgotPassword"
></hf-forgot-password>
