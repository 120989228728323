import {
  Component,
  ContentChild,
  ContentChildren,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  TemplateRef,
} from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { Subject } from 'rxjs';
import { DatePipe, DecimalPipe } from '@angular/common';
import { GenericTableData, GenericTableDef, GenericTableDefType } from '../table-data.dto';
import { GenericTableGridConfig, GenericTableService } from '../generic-table.service';
import { UtilsService } from '../../../services/utils.service';
import { EndColumnsInfo, StartColumnsInfo } from '../../../models/api-interfaces';
import { MatDialog } from '@angular/material/dialog';
import { ImagePreviewComponent } from '../../../components/file-upload/image-preview/image-preview.component';

@Component({
  selector: 'hf-generic-table-data-row',
  templateUrl: './generic-table-data-row.component.html',
  styleUrls: ['./generic-table-data-row.component.scss'],
})
export class GenericTableDataRowComponent<T extends GenericTableData<T>> implements OnInit, OnChanges {
  @Output()
  public selectionEmitter: Subject<SelectionModel<T>> = new Subject<SelectionModel<T>>();

  @Input()
  public selection = new SelectionModel<T>(true, []);

  @ContentChildren(GenericTableDataRowComponent) tableRows: QueryList<GenericTableDataRowComponent<T>>;

  @Input() public selectable = false;
  @Input() isOddRow = false;
  @Input() isDividerRow = false;
  @Input() public showActions = true;
  @Input() public showStart = true;
  @Input() height = '3.5rem';
  @Input() indicatorColor;

  @Input() public data: T;

  @Input()
  public set columns(val: GenericTableDef[]) {
    this.columnData = val;
  }

  @Input() startColumnsInfo: StartColumnsInfo[];
  @Input() endColumnsInfo: EndColumnsInfo[];
  @Input() xScrollable = false;

  @ContentChild('start', { static: false }) startTemplateRef: TemplateRef<any>;
  @ContentChild('end', { static: false }) endTemplateRef: TemplateRef<any>;

  public columnData: GenericTableDef[];

  public gridColumnTemplate = '';
  public isMobile = false;

  constructor(
    private datePipe: DatePipe,
    private utilsService: UtilsService,
    private genericTableService: GenericTableService,
    private numberPipe: DecimalPipe,
    public dialog: MatDialog,
    private utilService: UtilsService
  ) {}

  ngOnInit(): void {
    this.isMobile = this.utilsService.isMobile;
    if (!this.xScrollable) {
      this.xScrollable = this.isMobile;
    }
  }

  ngOnChanges() {
    const grindConfig: GenericTableGridConfig = {
      showActions: this.showActions,
      showStart: this.showStart,
      selectable: this.selectable,
    };
    this.gridColumnTemplate = this.genericTableService.createGridTemplate(
      grindConfig,
      this.columnData,
      this.startColumnsInfo,
      this.endColumnsInfo
    );
  }

  public toggleSelection(data: T) {
    this.selection.toggle(data);
    this.selectionEmitter.next(this.selection);
  }

  public getHref(column: GenericTableDef) {
    let href = null;
    if (column.href) {
      href = column.href(this.data);
    }
    return href;
  }

  public getData(column: GenericTableDef) {
    let value = null;
    if (column.property) {
      value = this.data[column.property];
    } else if (column.value) {
      value = column.value(this.data);
    }

    if (!value && !column.valueTransform) {
      return null;
    }

    const type = column.type ? column.type(this.data) : '';

    if (column.valueTransform) {
      value = column.valueTransform(this.data);

      switch (type) {
        case GenericTableDefType.date:
          value = this.datePipe.transform(value, 'dd.MM.yyy');
          break;
        case GenericTableDefType.num:
          value = this.transformNumber(value);
          break;
        case GenericTableDefType.longdate:
          value = this.datePipe.transform(value, 'dd.MM.yy, HH:mm');
          break;
        case GenericTableDefType.default:
          value = value;
          break;
      }

      if (column.valueUnit) {
        return `${value} ${column.valueUnit}`;
      }

      return value;
    }

    switch (type) {
      case GenericTableDefType.date:
        value = this.datePipe.transform(value, 'dd.MM.yyy');
        break;
      case GenericTableDefType.num:
        value = this.transformNumber(value);
        break;
      case GenericTableDefType.longdate:
        value = this.datePipe.transform(value, 'dd.MM.yy, HH:mm');
        break;
      case GenericTableDefType.defaultLongDate:
        value = this.datePipe.transform(value, 'longDate');
        break;
      case GenericTableDefType.default:
        value = value;
        break;
    }

    if (column.valueUnit) {
      return `${value} ${column.valueUnit}`;
    }

    return value;
  }

  private transformNumber(num: number): string {
    if (isNaN(num)) {
      return this.numberPipe.transform(0, '1.2-2', 'de');
    }
    return this.numberPipe.transform(num, '1.2-2', 'de');
  }

  public getProgressStyle(column: GenericTableDef) {
    const width = 100 * this.getData(column) ?? 0;
    return {
      background: this.indicatorColor,
      width: width + '%',
    };
  }

  public openImageDialog(column: GenericTableDef) {
    let images;
    if (column.images) {
      images = column.images(this.data);
    }
    if (images.length) {
      this.dialog.open(ImagePreviewComponent, {
        disableClose: false,
        width: '1024px',
        maxWidth: this.utilService.isMobile ? '100vw' : '80vw',
        data: { files: images, index: 0 },
      });
    }
  }
}
