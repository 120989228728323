import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'hf-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss'],
})
export class MenuItemComponent implements OnInit {
  @Input() item: string;
  @Input() iconLeft: string;
  @Input() iconRight: string;
  @Input() url: string;
  @Input() rightIconUrl: string;
  @Input() iconPath: string;
  @Input() disabled: boolean;
  @Input() counterActive = true;
  @Input() count = 0;
  @Input() colorScheme: string;
  @Input() increasePadding = false;

  iconClicked = false;

  constructor(private router: Router, public translate: TranslateService) {}

  ngOnInit(): void {}

  navigate(event: MouseEvent) {
    if (this.iconClicked || this.disabled) {
      event.stopPropagation();
      event.preventDefault();
    }
  }

  rightIconClick() {
    this.iconClicked = true;
    this.router.navigateByUrl(this.rightIconUrl);

    setTimeout(() => {
      this.iconClicked = false;
    }, 100);
  }
}
