import { Injectable } from '@angular/core';
import { PaidStatusEnum } from '@soda-models/paid-status';

@Injectable({
  providedIn: 'root',
})
export class ConversionHelperService {
  public static getBoolean(value): boolean {
    if (value) {
      return [true, 'true', 1, '1', 'on', 'yes'].includes(value);
    } else {
      return false;
    }
  }

  public static roundDecimal(num: number, places: number) {
    const factor = 10 ** places;
    return Math.round(num * factor) / factor;
  }

  public static getPayedStatus(payed: any): PaidStatusEnum {
    switch (payed) {
      case PaidStatusEnum.Paid:
        return PaidStatusEnum.Paid;
      case PaidStatusEnum.Unpaid:
        return PaidStatusEnum.Unpaid;
      case PaidStatusEnum.Downpayment:
        return PaidStatusEnum.Downpayment;
      default:
        return PaidStatusEnum.Unpaid;
    }
  }

  public static getMaxDate(date: Date = null) {
    if (!date) {
      date = new Date();
    }
    return new Date(date.getFullYear() + 2, date.getMonth(), date.getDate());
  }
}
