<div class="confirmation">
  <div class="confirmation__header">
    <img class="confirmation__logo" src='../../../assets/Logo.png'>
  </div>
  <mat-card class="confirmation__card">
    <h1 class="confirmation__card__title">{{ 'CONFIRMATION.TITLE' | translate}}</h1>
    <h2 class="confirmation__card__subheading"><strong>{{ 'CONFIRMATION.SUBTITLE' | translate}}</strong>
      <br> {{ 'CONFIRMATION.LOGGED_IN_ALREADY' | translate}}</h2>
    <mat-card-actions>
      <hf-text-button class="confirmation__card__go-to-login"
                      styleType="primary"
                      routerLink="/login"
                      hfColor="primary" [text]="('CONFIRMATION.GOTO_LOGIN' | translate)"></hf-text-button>
    </mat-card-actions>
  </mat-card>
</div>
