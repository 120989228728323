<div class="confirmation-dialog-container">
    <h1 *ngIf="data?.title" mat-dialog-title>{{ data?.title | translate }}</h1>
    <div mat-dialog-content>
        <p>{{ data?.text | translate }}</p>
    </div>
    <div class="actions" mat-dialog-actions>
        <hf-text-button hfColor="accent" styleType="ghost" text="{{ data?.cancelActionText | translate }}" (click)="performAction(false)">
        </hf-text-button>
        <hf-text-button styleType="primary" [hfColor]="color" text="{{data?.confirmActionText | translate }}" (click)="performAction(true)"></hf-text-button>
    </div>
</div>