<div class="onboarding-list">
  <div *ngFor="let item of source; let index = index" class="element">
    <div class="checkbox-col">
      <div [ngClass]="{'checked' : item.checked}">
        <mat-icon *ngIf="item.checked">
          check
        </mat-icon>
      </div>
    </div>
    <div class="text-col">
      <h2 [ngClass]="{'title-checked' : item.checked}">
        {{ item.title | translate}}
      </h2>
      <p>
        {{ item.description | translate }}
      </p>
    </div>
    <div class="button-col">
      <hf-icon-button styleType="primary"
                      *ngIf="!item.checked"
                      [icon]="item.buttonIcon"
                      hfColor="accent"
                      bold="true"
                      (click)="continueOnboarding(index)">
      </hf-icon-button>
    </div>
  </div>
</div>
