<div class="leave-review-wrapper">
  <div class="leave-review-wrapper__header">
    <h3>Leave a review</h3>
    <mat-icon (click)="close()">close</mat-icon>
  </div>

  <div mat-dialog-content class="leave-review-wrapper__content">
    <div class="leave-review-wrapper__content__star-section">
      <p>Rate your experience with this product and lessor from 1 to 5</p>
      <div>
        <hf-star-rating></hf-star-rating>
      </div>
    </div>

    <div class="leave-review-wrapper__content__textarea-section">
      <p>Let us and the community know about your feedback?</p>
      <textarea cols="30" [formControl]="text" rows="10"></textarea>
    </div>
  </div>

  <div mat-dialog-actions class="leave-review-wrapper__actions">
    <button (click)="close(true)">Continue</button>
  </div>
</div>
