export interface Theme {
  name: string;
  properties: any;
}

export const defaultTheme: Theme = {
  name: 'light',
  properties: {
    '--brand-color': '#583fbb',
    '--accent-color': '#2dc1a4',
    '--success-color': '#6ACE81',
    '--danger-color': '#F26157',
    '--waring-color': '#FF8A5B',
    '--info-color': '#85C7F2',
  }
};

export const dark: Theme = {
  name: 'dark',
  properties: {}
};

