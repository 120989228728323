import { AfterContentInit, Component, ContentChildren, Input, OnDestroy, OnInit, Output, QueryList } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GenericTableDataRowComponent } from '../generic-table-data-row/generic-table-data-row.component';

@Component({
  selector: 'hf-generic-table-data',
  templateUrl: './generic-table-data.component.html',
  styleUrls: ['./generic-table-data.component.scss'],
})
export class GenericTableDataComponent<T> implements OnInit, AfterContentInit, OnDestroy {
  @Output()
  public selectionEmitter: Subject<SelectionModel<T>> = new Subject<SelectionModel<T>>();
  @Input() maxHeight: string;

  private _rowComponents: QueryList<GenericTableDataRowComponent<T>>;
  @ContentChildren(GenericTableDataRowComponent)
  public set rowComponents(val: QueryList<GenericTableDataRowComponent<T>>) {
    this._rowComponents = val;
    this.initializeRows();
  }

  public get rowComponents(): QueryList<GenericTableDataRowComponent<T>> {
    return this._rowComponents;
  }

  private _selection: SelectionModel<T>;
  @Input()
  public set selection(val: SelectionModel<T>) {
    this._selection = val;
    this.rowComponents?.forEach((elem) => (elem.selection = val));
  }

  public get selection(): SelectionModel<T> {
    return this._selection;
  }

  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor() {}

  ngOnInit(): void {}

  ngAfterContentInit() {
    this.initializeRows();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }

  private initializeRows() {
    this.rowComponents.forEach((elem) => {
      elem.selection = this.selection;
      elem.selectionEmitter.pipe(takeUntil(this.destroy$)).subscribe((val) => {
        this.selection = val;
        this.selectionEmitter.next(this.selection);
      });
    });
  }
}
