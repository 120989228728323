<div class="chat-wrapper" [class.in-account]="inAccount" [class.account_messages]="inAccount && !showMobileMessages" [class.in-admin]="inAdmin">
  <div class="chat-wrapper__mobile_nav" *ngIf="isMobile && !showMobileMessages" (click)="onShowMobileMessages()">
    <div class="nav">
      <mat-icon matPrefix>keyboard_arrow_left</mat-icon>
      {{ 'GENERAL.MESSAGES' | translate }}
    </div>
  </div>
  <div class="chat-wrapper__users" *ngIf="showMobileMessages">
    <mat-form-field appearance="outline">
      <mat-label>Search</mat-label>
      <input [formControl]="searchInput" matInput placeholder="Search" />
      <mat-icon matPrefix>search</mat-icon>
    </mat-form-field>
    <div class="users-list">
      <div
        (click)="openMessagesByUser(u)"
        class="users-list__box"
        [class.selected]="receiver.id === u.id"
        *ngFor="let u of filteredUsers; trackBy: trackByFn">
        <div class="user-image">
          <img *ngIf="u.image?.url" [src]="u.image?.url" alt="" />
          <hf-user-avatar
            *ngIf="!u.image?.url"
            [name]="u.firstname + ' ' + u.lastname"
            [borderRadius]="inAdmin ? '50%' : '16px'"
            color="var(--grayscale-color-600)"></hf-user-avatar>
        </div>
        <div class="users-list__box__content">
          <div class="content-header">
            <h3
              class="users-list__box__title"
              [class.-bold]="!u.lastMessage?.read && u.lastMessage.receiver === currentUser._id">
              {{ u.firstname && u.lastname ? u.firstname + ' ' + u.lastname : u.email }}
              <span
                *ngIf="!u.lastMessage?.read && u.lastMessage.receiver === currentUser._id"
                class="online-badge"></span>
            </h3>
            <p>{{ u.lastMessage?.message ? u.lastMessage.message : u.lastMessage?.file?.name }}</p>
          </div>
          <span class="time">{{ u.lastMessage?.date | lastMessageDatePipe }}</span>
        </div>
      </div>
    </div>
    <div *ngIf="!disablePagination" class="chat-wrapper__users__pagination">
      <hf-paginator
        [itemsPerPage]="itemsPerPage"
        [totalItems]="totalItems"
        (currentPageChange)="currentPageChange($event)"></hf-paginator>
    </div>
  </div>
  <div class="chat-wrapper__messages" [class.show]="receiver" *ngIf="!isMobile || !showMobileMessages">
    <div class="chat-wrapper__messages__header">
      <div>
        <ng-container *ngIf="receiver">
          <div class="header-user">
            <img *ngIf="receiver.image?.url" src="{{ receiver.image?.url }}" />
            <div *ngIf="!receiver.image?.url" class="header-user__avatar">
              <hf-user-avatar
                [name]="receiver?.firstname + ' ' + receiver?.lastname"
                borderRadius="5px"
                color="var(--grayscale-color-600)"></hf-user-avatar>
            </div>
            <p>
              {{
                receiver.firstname && receiver.lastname
                  ? receiver?.firstname + ' ' + receiver?.lastname
                  : receiver?.email
              }}
            </p>
          </div>
        </ng-container>
      </div>
      <img *ngIf="receiver?.image" src="{{ receiver.image?.url }}" />
      <div *ngIf="!receiver?.image && receiver?.firstname && receiver?.lastname" class="header-user__avatar--large">
        <hf-user-avatar
          [name]="receiver?.firstname + ' ' + receiver?.lastname"
          borderRadius="16px"
          fontSize="24px"
          color="var(--grayscale-color-600)"></hf-user-avatar>
      </div>
    </div>
    <div class="chat-wrapper__messages__content" #content>
      <!--  (scroll)="onScroll($event)"-->
      <ng-container *ngFor="let gm of _groupedMessages">
        <div class="date">
          {{ gm._id | date: 'EEEE, d MMMM' }}
        </div>
        <div
          [class.not-message-box]="m.type"
          [class.host]="m.sender?._id === currentUser?._id"
          class="message"
          *ngFor="let m of gm.list">
          <ng-container *ngIf="m.type === messageType.message">
            <p class="message__text">
              {{ m.message }}

              <ng-container *ngIf="m.file">
                <a [href]="m.file.url" target="__blank">{{ m.file.name }}</a>
              </ng-container>
            </p>
            <span class="message__time">{{ m.date | date: 'shortTime' }}</span>
          </ng-container>
          <hf-messages-box
            [additionalData]="{ sender: m.sender?._id }"
            [message]="m"
            *ngIf="m.type !== messageType.message"
            [type]="m.type"
            [currentUser]="currentUser"
            (eventClicked)="eventClicked($event, m.request._id)"></hf-messages-box>
        </div>
      </ng-container>
    </div>
    <div class="chat-wrapper__messages__footer" *ngIf="receiver">
      <div class="msg-icons" *ngIf="attachments">
        <input type="file" accept="image/*" (change)="processFile($event.target.files[0])" #img style="display: none" />
        <input
          type="file"
          accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          (change)="processFile($event.target.files[0])"
          #files
          style="display: none" />
        <img class="img-icon" (click)="img.click()" src="assets/image.png" alt="" />
        <img src="assets/attachment.png" (click)="files.click()" alt="" />
      </div>
      <mat-form-field appearance="outline">
        <mat-label>{{ 'GENERAL.WRITE_YOUR_MESSAGE' | translate }}</mat-label>
        <input
          (keyup.enter)="sendMessage()"
          [formControl]="message"
          autocomplete="off"
          matInput
          placeholder="{{ 'GENERAL.WRITE_YOUR_MESSAGE' | translate }}..." />
        <button (click)="sendMessage()" class="message-button" matSuffix mat-icon-button>
          <mat-icon>arrow_right</mat-icon>
        </button>
      </mat-form-field>
    </div>
  </div>
</div>
