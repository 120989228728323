export const numberPattern = new RegExp('^(?=.*?[0-9])');
export const specialCharacterPattern = new RegExp('^(?=.*?[#?!@$%^&*-])');
export const uppercaseCharacterPattern = new RegExp('^(?=.*[A-Z])');
export const urlPattern = new RegExp(
  /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/
);
export const itemRef = 'rentable-item';
export const roomRef = 'room';
export const infoRef = 'account-settings';
export const userRef = 'user';
export const userPermissionsSource = 'users-permissions';
export const fieldImages = 'images';
export const fieldImage = 'Image';
export const arrival = 'arrival';
export const departure = 'departure';
export const start = 'start';
export const end = 'end';
export const name = 'GENERAL.NAME';
export const room = 'GENERAL.ROOM';
export const adults = 'GENERAL.ADULTS';
export const children = 'GENERAL.CHILDREN';
export const rentals = 'rentals';
export const bookings = 'bookings';
export const requests = 'requests';
export const item = 'GENERAL.ITEM';
export const rental = 'GENERAL.RENTAL';
export const bookingChannel = 'GENERAL.CHANNEL';
export const createdAt = 'createdAt';
export const orderDesc = 'desc';
export const defaultCheckInHours = '12:00';
export const defaultCheckOutHours = '10:00';
export const roles = {
  staff: 'staff',
  unsubscribed: 'unsubscribed',
};
export const defaultStartWorkingHours = '09:00';
export const defaultEndWorkingHours = '17:00';
export const AustriaCountryCodes = ['AUT', 'AT'];
export const termsAndConditionsUrl = 'https://rent2b.net/terms';
export const needitTermsAndConditionsUrl = 'https://needit.at/terms';
export const privacyPolicyUrl = 'https://rent2b.net/privacy-policy';
export const needitPrivacyPolicyUrl = 'https://needit.at/privacy-policy';
export const summary = 'GENERAL.SUMMARY';
