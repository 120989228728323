<label
  class="checkbox-container"
  [ngClass]="{'label-after' : labelPosition === 'after', 'label-before' : labelPosition === 'before'}">
  <input
    [ngClass]="{'checked' : value === 'true'}"
    type="checkbox"
    (click)="doInput()"
    (blur)="doBlur()"
    [formControl]="checkboxInput">
    <span [id]="id" class="checkmark"></span>
  <mat-label>
    {{label}}
    <mat-icon class="tooltip-content" *ngIf="tooltipContent" [matTooltip]="tooltipContent" matTooltipPosition="after">
      info</mat-icon>
  </mat-label>
</label>
