import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';

import { StrapiFile } from '@shared-library-models/api-interfaces';

@Injectable({
  providedIn: 'root',
})
export class UploadFileService {
  private uploadFileEndpoint = `${this.environment.apiEndpoint}/upload`;
  private uploadFileEndpointV2 = `${this.environment.apiEndpointV2}/upload-files`;

  constructor(private http: HttpClient, @Inject('env') private environment) {}

  public getAllFiles(): Observable<StrapiFile[]> {
    const url = `${this.uploadFileEndpoint}/files`;
    return this.http.get<StrapiFile[]>(url);
  }

  public getFileById(id: string): Observable<StrapiFile> {
    const url = `${this.uploadFileEndpoint}/files/${id}`;
    return this.http.get<StrapiFile>(url);
  }

  public deleteFileById(id: string): Observable<StrapiFile> {
    const url = `${this.uploadFileEndpointV2}/${id}`;
    return this.http.delete<StrapiFile>(url);
  }

  public uploadFile(formData: FormData): Observable<StrapiFile[]> {
    const url = `${this.uploadFileEndpoint}`;
    return this.http.post<StrapiFile[]>(url, formData);
  }

  public uploadFileV2(formData: FormData): Observable<any> {
    return this.http.post(this.uploadFileEndpoint, formData, {
      reportProgress: true,
      observe: 'events',
    });
  }
}
