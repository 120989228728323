import { Component, Input, OnInit } from '@angular/core';
import {  ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'hf-slide-toggle',
  templateUrl: './slide-toggle.component.html',
  styleUrls: ['./slide-toggle.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: SlideToggleComponent
    }
  ]
})
export class SlideToggleComponent implements OnInit, ControlValueAccessor {

  @Input() hfColor: string;
  @Input() checked: boolean;
  @Input() labelPosition: 'before' | 'after';
  @Input() required: boolean;
  @Input() disabled: boolean;
  @Input() text: string;

  private onChange = (value: boolean) => {};
  private onTouched = () => {};

  constructor() { }

  writeValue(obj: boolean): void {
    this.checked = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  ngOnInit(): void {

  }

  setValue() {
    this.checked = !this.checked;
    this.onChange(this.checked);
    this.onTouched();
  }

}
