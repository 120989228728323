import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { IconButtonComponent } from './icon-button.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ColorModule } from '../colors/color.module';


@NgModule({
  declarations: [IconButtonComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatIconModule,
    MatButtonModule,
    ColorModule,
    MatTooltipModule
  ],
  exports: [IconButtonComponent]
})
export class IconButtonModule {
}
