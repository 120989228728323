import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { DropdownMenuComponent } from './dropdown-menu.component';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatDividerModule } from '@angular/material/divider';
import { TranslateModule } from '@ngx-translate/core';
import { UserAvatarModule } from '../user-avatar/user-avatar.module';

@NgModule({
  declarations: [DropdownMenuComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    RouterModule,
    MatIconModule,
    MatMenuModule,
    MatDividerModule,
    TranslateModule,
    UserAvatarModule,
  ],
  exports: [DropdownMenuComponent],
})
export class DropdownMenuModule {}
