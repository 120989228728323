<div class="pagination">
  <span class="text">{{ currentPage }} of {{ totalPages }}</span>

  <mat-icon
    (click)="!pagination.isFirst && pagination.prev()"
    [class.disabled]="pagination.isFirst"
    svgIcon="hf-left-arrow"
  ></mat-icon>
  <mat-icon
    (click)="!pagination.isFirst && pagination.first()"
    [class.disabled]="pagination.isFirst"
    svgIcon="hf-double-left-arrow"
  ></mat-icon>
  <mat-icon
    (click)="!pagination.isLast && pagination.last()"
    [class.disabled]="pagination.isLast"
    svgIcon="hf-double-right-arrow"
  ></mat-icon>
  <mat-icon
    (click)="!pagination.isLast && pagination.next()"
    [class.disabled]="pagination.isLast"
    svgIcon="hf-right-arrow"
  ></mat-icon>

  <input
    hfPagination
    #pagination="hfPagination"
    [totalItems]="totalItems"
    (totalPagesChange)="setTotalPages($event)"
    (pageChange)="onPageChange($event)"
    [itemsPerPage]="itemsPerPage"
  />
</div>
