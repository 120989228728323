import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[hfSideBar]'
})
export class SideBarDirective implements OnInit {

  @Input() color;

  constructor(private element: ElementRef) {
    element.nativeElement.classList.add('side-bar');
  }

  ngOnInit() {
    this.element.nativeElement.classList.add(this.color);
  }

}
