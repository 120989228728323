<mat-form-field
  [style.width]="width"
  appearance="outline"
  [class]="{'mat-form-field-invalid': urlPatternCheck}">
  <mat-label>
    {{ placeholder }}
  </mat-label>
  <textarea
    *ngIf="!matAutocomplete && textarea"
    matInput
    cdkTextareaAutosize
    cdkAutosizeMinRows="4"
    [value]="textInput.value"
    [type]="type"
    (input)="doInput($event)"
    (blur)="doBlur()"
    [formControl]="textInput"
    [required]="required"></textarea>
  <input
    *ngIf="!matAutocomplete && !textarea"
    matInput
    [value]="textInput.value"
    [type]="type"
    [min]="min"
    [max]="max"
    (input)="doInput($event)"
    (blur)="doBlur()"
    [formControl]="textInput"
    [required]="required">
  <input
    *ngIf="matAutocomplete && !textarea"
    matInput
    [value]="textInput.value"
    [type]="type"
    [min]="min"
    [max]="max"
    (input)="doInput($event)"
    (blur)="doBlur()"
    [formControl]="textInput"
    [required]="required"
    [matAutocomplete]="matAutocomplete">
  <mat-icon
    *ngIf="icon && iconAlign === 'left'
      || icon && !iconAlign"
    matPrefix>
    {{ icon }}
  </mat-icon>
  <mat-icon
    *ngIf="icon && iconAlign === 'right'"
    matSuffix>
    {{ icon }}
  </mat-icon>
  <!-- common hints -->
  <mat-hint class="input-hint"
            *ngIf="hint"
            [id]="null">
    {{ hint }}
  </mat-hint>
  <!-- Password input hints -->
  <mat-hint class="input-hint" *ngIf="!hint"
            [id]="null">
      <span *ngIf="!error && numPatternCheck">
        <mat-icon>check</mat-icon>
        One number
      </span>
    <span *ngIf="!error && specialCharPattern">
        <mat-icon>check</mat-icon>
        One special character
      </span>
      <span *ngIf="!error && uppercaseCharacterPattern">
        <mat-icon>check</mat-icon>
        One uppercase character
      </span>
      <span *ngIf="!error && minLengthPattern">
        <mat-icon>check</mat-icon>
        Min length: 8
      </span>
  </mat-hint>
  <ng-content select="[hf-autocomplete]"></ng-content>
</mat-form-field>
<mat-hint class="input-error" *ngIf="error">
  {{ error }}
</mat-hint>
<mat-hint class="input-error" *ngIf="urlPatternCheck">
  {{ ('ERRORS.URL_INVALID' | translate) }}
</mat-hint>
