import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { User, UserDto } from 'honeyfield-component-library';
import { Store } from '@ngrx/store';
import { State } from '../+store/reducers';
import { registerUserSuccess } from '../+store/actions/user.actions';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public static user: User;
  public static token: string;
  public static refreshToken: string;
  public static refreshing = false;
  private apiEndpoint = `${environment.apiEndpoint}`;

  constructor(private http: HttpClient, private router: Router, private store: Store<State>) {
    const persist = localStorage.getItem('persist');
    if (persist) {
      AuthService.token = localStorage.getItem('token');
      AuthService.refreshToken = localStorage.getItem('refreshToken');
      AuthService.user = JSON.parse(localStorage.getItem('user'));
    } else {
      AuthService.token = sessionStorage.getItem('token');
      AuthService.refreshToken = sessionStorage.getItem('refreshToken');
      AuthService.user = JSON.parse(sessionStorage.getItem('user'));
    }
  }

  public login(email, password, persist): Observable<User> {
    const url = `${this.apiEndpoint}/auth/authenticate`;
    return this.http.post<any>(url, { email, password }).pipe(
      tap((result) => this.storeUserData(result, persist)),
      map((userResponse) => {
        const user: User = {
          username: userResponse.user.Username,
          email: userResponse.user.Username,
          _id: userResponse.user._id,
          createdAt: userResponse.user.createdAt,
          firstname: userResponse.user.firstname,
          lastname: userResponse.user.lastname,
        };
        localStorage.setItem('persist', persist);

        // this.accountFeatures.getFeatures().subscribe();
        return user;
      })
    );
  }

  public register(email, password, fullName): Observable<boolean> {
    const url = `${this.apiEndpoint}/auth/register`;
    return this.http
      .post<any>(url, { email, password, fullName })
      .pipe(tap(() => this.store.dispatch(registerUserSuccess(null))));
  }

  public initiateForgotPassword(email: string): Observable<any> {
    const url = `${this.apiEndpoint}/auth/initiate-forgot-password`;
    return this.http.post<any>(url, { email });
  }

  public resetPassword(email: string, code: string, newPassword: string): Observable<any> {
    const url = `${this.apiEndpoint}/auth/change-forgot-password`;
    return this.http.post<any>(url, { email, code, newPassword });
  }

  public changePassword(data: any): Observable<any> {
    const url = `${this.apiEndpoint}/auth/change-password`;
    return this.http.post<any>(url, data);
  }

  public isUserLoggedIn(): boolean {
    return AuthService.token && AuthService.token.length > 0;
  }

  public checkTokenExpirationTime(): number {
    const decoded: any = jwt_decode(AuthService.token);
    if (decoded.exp === undefined) {
      return null;
    }

    const date = new Date(0);
    date.setUTCSeconds(decoded.exp);
    const dif = (date.getTime() - new Date().getTime()) / 1000;

    return dif;
  }

  public logout(): any {
    const url = `${this.apiEndpoint}/auth/logout`;
    return this.http.get<boolean>(url);
  }

  public refreshToken(): Observable<any> {
    AuthService.refreshing = true;
    const url = `${this.apiEndpoint}/auth/refresh`;
    return this.http.post<any>(url, { refreshToken: AuthService.refreshToken }).pipe(
      tap(),
      map((result) => {
        if (!result) {
          this.clearStorage();
          this.router.navigate(['/login']);
        }
        AuthService.token = result.idToken.jwtToken;
        AuthService.refreshToken = result.refreshToken.token;

        if (localStorage.getItem('persist')) {
          localStorage.setItem('token', result.idToken.jwtToken);
          localStorage.setItem('refreshToken', result.refreshToken.token);
        } else {
          sessionStorage.setItem('token', result.idToken.jwtToken);
          sessionStorage.setItem('refreshToken', result.refreshToken.token);
        }
        AuthService.refreshing = false;
      })
    );
  }

  public clearStorage(): void {
    AuthService.token = null;
    sessionStorage.removeItem('token');
    localStorage.removeItem('token');
    AuthService.refreshToken = null;
    sessionStorage.removeItem('refreshToken');
    localStorage.removeItem('refreshToken');
    AuthService.user = null;
    sessionStorage.removeItem('user');
    localStorage.removeItem('user');
  }

  private storeUserData(userResponse: any, persist) {
    AuthService.token = userResponse.token;
    AuthService.refreshToken = userResponse.refreshToken;

    if (userResponse.user) {
      AuthService.user = {
        username: userResponse.user.Username,
        email: userResponse.user.Username,
        _id: userResponse.user._id,
        createdAt: new Date(),
        firstname: userResponse.user.Username,
        lastname: userResponse.user.Username,
      };

      if (persist) {
        localStorage.setItem('user', JSON.stringify(AuthService.user));
        localStorage.setItem('token', userResponse.token);
        localStorage.setItem('refreshToken', userResponse.refreshToken);
        localStorage.setItem('persist', 'true');
      } else {
        sessionStorage.setItem('user', JSON.stringify(AuthService.user));
        sessionStorage.setItem('token', userResponse.token);
        sessionStorage.setItem('refreshToken', userResponse.refreshToken);
        localStorage.removeItem('persist');
      }
    } else {
      console.error('Could not load user profile!');
    }
  }

  public navigateToLogout() {
    this.clearStorage();
    this.router.navigate(['/login']);
  }
}
