import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'hf-pillow',
  templateUrl: './pillow.component.html',
  styleUrls: ['./pillow.component.scss']
})
export class PillowComponent implements OnInit {

  @Input()
  public data: Array<string> = [];

  constructor() { }

  ngOnInit(): void {
  }

  removeKeyword(item: string) {
    this.data.splice(this.data.indexOf(item), 1);
  }
}
