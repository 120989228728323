<div class="placeholder">
    <div class="placeholder__square"></div>
    <div class="placeholder__column-lines">
        <div class="placeholder__column-lines__longer-line"></div>
        <div class="placeholder__column-lines__shorter-line"></div>
    </div>
    <div class="placeholder__spacer"></div>
    <div class="placeholder__row-line-circle">
        <div class="placeholder__row-line-circle__left-line"></div>
        <div class="placeholder__row-line-circle__right-line"></div>
        <div class="placeholder__row-line-circle__circle"></div>
    </div>
</div>
