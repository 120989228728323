import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({ name: 'lastMessageDatePipe' })
export class LastMessageDatePipe implements PipeTransform {
  constructor(private datePipe: DatePipe, private translate: TranslateService) {}

  transform(value: string): string {
    if (!value) return '';

    const currentDate = new Date();
    const messageDate = new Date(value);
    const browserLang = this.translate.getBrowserLang();

    const num = Math.abs(new Date(currentDate).getTime() - messageDate.getTime()) / (60 * 1000);
    const days = Math.floor(num / 24 / 60);
    const hours = (num / 60) % 24;
    const rhours = Math.floor(hours);
    const minutes = (hours - rhours) * 60;
    const rminutes = Math.round(minutes);

    if (days >= 1 && days <= 3) {
      return browserLang === 'de' ? `vor ${days} Tage` : `${days}d ago`;
    } else if (days > 3) return this.datePipe.transform(value, 'dd/MM/yyyy');
    else if (hours > 1) {
      return browserLang === 'de' ? `vor ${rhours} Stunden` : `${rhours}h ago`;
    } else if (rminutes === 0) {
      return browserLang === 'de' ? 'Jetzt' : 'Now';
    } else return browserLang === 'de' ? `vor ${rminutes}m` : `${rminutes}m ago`;
  }
}
