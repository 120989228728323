import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import {
  User,
  StripeInvoice,
  StripeSubscription,
  StripeProduct,
  StripeCard,
  AccountFeatures,
  Place,
  OnboardingSetting,
} from '@shared-library-models/api-interfaces';

export enum OnboardingEnum {
  Uncompleted = 'uncompleted',
  Completed = 'completed',
  InProgress = 'inprogress',
  Skipped = 'skipped',
}

@Injectable({
  providedIn: 'root',
})
export class AccountFeaturesService {
  public features$: BehaviorSubject<AccountFeatures> = new BehaviorSubject<AccountFeatures>(null);

  private featuresEndpoint = `${this.environment.apiEndpoint}/account-settings`;

  constructor(private http: HttpClient, @Inject('env') private environment) {}

  public getFeatures(): Observable<AccountFeatures[]> {
    return this.http.get<AccountFeatures[]>(this.featuresEndpoint).pipe(tap((val) => this.features$.next(val[0])));
  }

  public setFeatures(features: AccountFeatures): Observable<AccountFeatures> {
    return this.http
      .put<AccountFeatures>(this.featuresEndpoint, features)
      .pipe(tap(() => this.features$.next(features)));
  }

  public findPlaces(text: string = null): Observable<Place[]> {
    return this.http.get<Place[]>(`${this.featuresEndpoint}/places?searchText=${text}`);
  }

  public loadInvoices(): Observable<StripeInvoice[]> {
    return this.http.get<StripeInvoice[]>(`${this.environment.apiEndpoint}/payment/invoices`);
  }

  public resendInvoice(invoiceId: string): Observable<boolean> {
    return this.http.get<boolean>(`${this.environment.apiEndpoint}/payment/invoices/resend/${invoiceId}`);
  }

  public cancelSubscription(): Observable<StripeSubscription> {
    return this.http.delete<StripeSubscription>(`${this.environment.apiEndpoint}/payment/subscription/cancel`);
  }

  public getProducts(): Observable<StripeProduct[]> {
    return this.http.get<StripeProduct[]>(`${this.environment.apiEndpoint}/payment/products`).pipe(
      map((data) =>
        data
          .filter((val) => val.active)
          .map((d) => ({
            ...d,
            metadata: {
              ...d.metadata,
              badge: JSON.parse(d.metadata?.badge),
              features: d.metadata?.features?.split(','),
            },
          }))
      )
    );
  }

  public updateUser(user: User): Observable<User> {
    return this.http.put<User>(`${this.environment.apiEndpoint}/payment/user`, user);
  }

  public getCurrentUserCards(): Observable<StripeCard[]> {
    return this.http.get<StripeCard[]>(`${this.environment.apiEndpoint}/payment/cards`);
  }

  public createCard(body: any) {
    const url = `${this.environment.apiEndpoint}/payment/card`;
    return this.http.post<any>(url, body);
  }

  public deleteCard(cardId: string): Observable<StripeCard> {
    const url = `${this.environment.apiEndpoint}/payment/card/${cardId}`;
    return this.http.delete<StripeCard>(url);
  }

  public updateCard(body: any, cardId: string): Observable<StripeCard> {
    const url = `${this.environment.apiEndpoint}/payment/card/${cardId}`;
    return this.http.put<StripeCard>(url, body);
  }

  public getUserLocation() {
    const url = 'http://ip-api.com/json';
    return this.http.get(url);
  }

  public sendToken(token: string): Observable<any> {
    return this.http.post<any>(`${this.environment.apiEndpoint}/account/validateRecaptcha`, { recaptcha: token });
  }

  public getOnboardingBusinessSettings(): Observable<OnboardingSetting> {
    return this.http.get<OnboardingSetting>(`${this.featuresEndpoint}/onboarding-business`);
  }

  public setOnboardingBusinessSettings(setting: OnboardingSetting): Observable<OnboardingSetting> {
    return this.http.post<OnboardingSetting>(`${this.featuresEndpoint}/onboarding-business`, setting);
  }

  public updateOnBoardingBusinessSettings(setting: OnboardingSetting): Observable<OnboardingSetting> {
    return this.http.put<OnboardingSetting>(`${this.featuresEndpoint}/onboarding-business`, setting);
  }

  public getNonWorkingDays(): Observable<any> {
    return this.http.get<any>(`${this.featuresEndpoint}/non-working-days`);
  }

  public getLessor(lessorId): Observable<AccountFeatures[]> {
    return this.http
      .get<AccountFeatures[]>(`${this.featuresEndpoint}/user/${lessorId}`)
      .pipe(tap((val) => this.features$.next(val[0])));
  }

  public getPublicProfileInfo(userId): Observable<any> {
    return this.http.get<any>(`${this.featuresEndpoint}/public-profile-info/user/${userId}`);
  }
}
