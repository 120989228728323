<div class="generic-table-data-row" (click)="data.expanded = !data.expanded" [class.fit-content]="xScrollable"
     [class.generic-table-data-row--odd]="!isOddRow" [class.generic-table-data-row--divider]="isDividerRow"
     [style.grid-template-columns]="gridColumnTemplate" [style.height]="height">
  <div *ngIf="indicatorColor" class="generic-table-data-row__color-indicator" [style.background]="indicatorColor"></div>

  <div class="generic-table-data-row-item generic-table-data-row-item--select" [class.padding-0]="!selectable"
       [class.generic-table-data-row--odd]="!isOddRow" [class.bg-white]="isOddRow">
    <mat-checkbox (click)="$event.stopPropagation()" *ngIf="selectable" (change)="$event ? toggleSelection(data) : null"
                  [checked]="selection.isSelected(data)"></mat-checkbox>
  </div>

  <div class="generic-table-data-row-item--start" *ngIf="showStart">
    <ng-content select="[role=start]"></ng-content>
  </div>

  <ng-container *ngIf="startColumnsInfo !== null">
    <ng-container *ngFor="let item of startColumnsInfo; let i = index">
      <div class="generic-table-data-row-item--start-sticky"
           [ngStyle]="{'left': item.leftPx + (selectable ? 50 : 0) + 'px' }"
           [class.generic-table-data-row--odd]="!isOddRow" [class.bg-white]="isOddRow">
        <ng-container *ngIf="startTemplateRef" [ngTemplateOutlet]="startTemplateRef"
                      [ngTemplateOutletContext]="{$implicit:item}"></ng-container>
      </div>
    </ng-container>
  </ng-container>

  <ng-container *ngFor="let column of columnData">
    <ng-container [ngSwitch]="!column.type || column.type(data)">
      <div *ngSwitchDefault class="generic-table-data-row-item text-wrapping" [class.min-width-250]="xScrollable"
           [ngStyle]="column.dataStyle ? column.dataStyle(data) : null">
        {{ getData(column) }}
      </div>
      <div *ngSwitchCase="'IMAGE'" class="generic-table-data-row-item image"
           [ngStyle]="column.dataStyle ? column.dataStyle(data) : null">
        <img [src]="getHref(column)" (click)="openImageDialog(column); $event.stopPropagation()">
      </div>
      <div *ngSwitchCase="'ICON'" class="generic-table-data-row-item"
           [ngStyle]="column.dataStyle ? column.dataStyle(data) : null" [class.min-width-250]="xScrollable">
        <mat-icon>{{ column.icon(data) }}</mat-icon>
      </div>
      <div *ngSwitchCase="'LINK'" class="generic-table-data-row-item text-wrapping" [class.min-width-250]="xScrollable">
        <a [ngStyle]="column.dataStyle ? column.dataStyle(data) : null" [href]="getHref(column)"
           (click)="$event.stopPropagation()">
          {{ getData(column) }}
        </a>
      </div>
      <div *ngSwitchCase="'LABEL'" class="generic-table-data-row-item" [class.min-width-250]="xScrollable"
           [ngStyle]="column.dataStyle ? column.dataStyle(data) : null">
        <mat-chip [disableRipple]="true" [selected]="false">
          <mat-icon *ngIf="column.icon && column.icon(data)">{{ column.icon(data) }}</mat-icon>
          {{ getData(column) }}
        </mat-chip>
      </div>

      <div *ngSwitchCase="'COMPARE'" class="generic-table-data-row-item" [class.min-width-250]="xScrollable"
           [ngStyle]="column.dataStyle ? column.dataStyle(data) : null">
        <span>
          {{ data[column.property] && data[column.property][0] ? (data[column.property][0] | number: '':'de') : '' }}
        </span>
        <br />
        <span [style.color]="'red'">
          {{ data[column.property] && data[column.property][1] ? (data[column.property][1] | number: '':'de') : '-' }}
        </span>
      </div>

      <div *ngSwitchCase="'RATE'" class="generic-table-data-row-item generic-table-data-row-item--progress"
           [ngStyle]="column.dataStyle ? column.dataStyle(data) : null" [class.min-width-250]="xScrollable">
        <div class="generic-table-data-row-item--progress-bar">
          <span [ngStyle]="getProgressStyle(column)"></span>
        </div>
        <div>{{ getData(column) * 100 | number: '1.2-2' }} %</div>
      </div>

      <div *ngSwitchCase="'CATEGORY'" class="generic-table-data-row-item" [class.min-width-250]="xScrollable">
        <hf-star [category]="data[column.property] ?? 0"></hf-star>
      </div>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="endColumnsInfo !== null">
    <ng-container *ngFor="let item of endColumnsInfo; let i = index">
      <div class="generic-table-data-row-item--end-sticky" [ngStyle]="{'right': item.rightPx + 'px' }"
           [class.generic-table-data-row--odd]="!isOddRow" [class.bg-white]="isOddRow">
        <ng-container *ngIf="endTemplateRef" [ngTemplateOutlet]="endTemplateRef"
                      [ngTemplateOutletContext]="{$implicit:item}"></ng-container>
      </div>
    </ng-container>
  </ng-container>

  <div class="generic-table-data-row-item--end" *ngIf="showActions">
    <ng-content select="[role=end]"></ng-content>
  </div>
</div>

<div class="generic-table-data-row__content" *ngIf="data?.expanded" [class.fit-content]="xScrollable">
  <!-- PER DESIGN THERE IS NO NEEDED FOR INDICATOR COLOR IN EXPANDED AREA IN ANY GENERIC TABLE, IF YOU STIL THINK THAT WE SHOULD KEEP IT WE JUST NEED TO UNCOMMENT BELOW CODE -->
  <!-- <div *ngIf="indicatorColor"
       class="generic-table-data-row__color-indicator generic-table-data-row__color-indicator--detail"
       [style.background]="indicatorColor"></div> -->
  <ng-content select="[role=content]"></ng-content>
</div>
