import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'hf-star-rating',
  templateUrl: './star-rating.component.html',
  styleUrls: ['./star-rating.component.scss'],
})
export class StarRatingComponent implements OnInit {
  currentStar: FormControl = new FormControl(0);
  constructor() {}

  ngOnInit(): void {}

  rate(star) {
    this.currentStar.patchValue(star);
  }
}
