<div class="register-container-v2">
    <div class="form-container">
        <h2>{{ 'LOGIN.REGISTER' | translate }}</h2>
        <form [formGroup]="registerForm" (ngSubmit)="submit()">
            <hf-input-text [placeholder]="firstNamePlaceholder | translate" iconAlign="right" icon="person"
                formControlName="firstName" type="text" [value]="this.registerForm.get('firstName').value" required="true">
            </hf-input-text>
            <div class="error-container">
                <mat-hint *ngIf="getErrors('firstName')">
                    {{ firstNameError | translate }}
                </mat-hint>
            </div>
            <hf-input-text [placeholder]="lastNamePlaceholder | translate" iconAlign="right" icon="person"
                formControlName="lastName" type="text" [value]="this.registerForm.get('lastName').value" required="true">
            </hf-input-text>
            <div class="error-container">
                <mat-hint *ngIf="getErrors('lastName')">
                    {{ lastNameError | translate }}
                </mat-hint>
            </div>
            <hf-input-text [placeholder]="emailPlaceholder | translate" iconAlign="right" icon="email"
                formControlName="email" type="email" email="true" [value]="this.registerForm.get('email').value"
                required="true">
            </hf-input-text>
            <div class="error-container">
                <mat-hint *ngIf="getErrors('email') && this.registerForm.get('email').touched">
                    {{ emailError | translate }}
                </mat-hint>
            </div>
            <hf-input-text [placeholder]="passwordPlaceholder | translate" iconAlign="right" icon="lock"
                formControlName="password" type="password" [error]="passwordError"
                [value]="this.registerForm.get('password').value" required="true">
            </hf-input-text>
            <div class="hint-container">
                <div class="hint-container__row">
                    <mat-hint
                        [ngClass]="!this.registerForm.get('password').value ? 'hint-info-color' : this.registerForm.get('password').value && numPatternCheck ? 'hint-success-color' : 'hint-danger-color'">
                        <span>
                            <mat-icon>{{(this.registerForm.get('password').value && numPatternCheck ||
                                !this.registerForm.get('password').value) ? 'check' : 'close'}}</mat-icon>
                            {{'GENERAL.ONE_NUMBER' | translate}}
                        </span>
                    </mat-hint>
                    <mat-hint
                        [ngClass]="!this.registerForm.get('password').value ? 'hint-info-color' : this.registerForm.get('password').value && specialCharPattern ? 'hint-success-color' : 'hint-danger-color'">
                        <span>
                            <mat-icon>{{(this.registerForm.get('password').value && specialCharPattern ||
                                !this.registerForm.get('password').value) ? 'check' : 'close'}}</mat-icon>
                            {{'GENERAL.ONE_SPECIAL_CHARACTER' | translate}}
                        </span>
                    </mat-hint>
                </div>
                <div class="hint-container__row">
                    <mat-hint
                        [ngClass]="!this.registerForm.get('password').value ? 'hint-info-color' : this.registerForm.get('password').value && uppercaseCharacterPattern ? 'hint-success-color' : 'hint-danger-color'">
                        <span>
                            <mat-icon>{{(this.registerForm.get('password').value && uppercaseCharacterPattern ||
                                !this.registerForm.get('password').value) ? 'check' : 'close'}}</mat-icon>
                            {{'GENERAL.ONE_UPPERCASE_CHARACTER' | translate}}
                        </span>
                    </mat-hint>
                    <mat-hint
                        [ngClass]="!this.registerForm.get('password').value ? 'hint-info-color' : this.registerForm.get('password').value && minLengthPattern ? 'hint-success-color' : 'hint-danger-color'">
                        <span>
                            <mat-icon>{{(this.registerForm.get('password').value && minLengthPattern ||
                                !this.registerForm.get('password').value) ? 'check' : 'close'}}</mat-icon>
                            {{'GENERAL.MIN_LENGTH_8' | translate}}
                        </span>
                    </mat-hint>
                </div>
            </div>
            <div class="error-container">
                <mat-hint *ngIf="getErrors('password')">
                    {{ passwordError | translate }}
                </mat-hint>
            </div>
            <password-strength-meter class="mt-20" [password]="this.registerForm.get('password').value"
                [minPasswordLength]="8"></password-strength-meter>
            <hf-input-text [placeholder]="repeatPasswordPlaceholder | translate" iconAlign="right" icon="lock"
                formControlName="repeatPassword" type="password" [value]="this.registerForm.get('repeatPassword').value"
                required="true">
            </hf-input-text>
            <div class="error-container">
                <mat-error *ngIf="this.registerForm.get('repeatPassword').hasError('matching') && this.registerForm.get('repeatPassword').touched">
                    {{'ERRORS.PASSWORDS_MATCH' | translate}}
                </mat-error>
            </div>
            <div class="accept-tc" *ngIf="showTC">
                <hf-checkbox [id]="'accepttc'" formControlName="acceptTC" labelPosition="after">
                </hf-checkbox>
                <span>{{'REGISTER.ACCEPT_TC' | translate}}<b (click)="openTC()">{{ 'REGISTER.TC' | translate}}</b></span>
            </div>
            <div class="accept-tc" *ngIf="showTC">
                <hf-checkbox [id]="'acceptgdpr'" formControlName="acceptGDPR" labelPosition="after">
                </hf-checkbox>
                <span>{{'REGISTER.ACCEPT_GDPR' | translate}}<b (click)="openGDPR()">{{ 'REGISTER.GDPR' |
                        translate}}</b></span>
            </div>
            <br>
            <hf-text-button [text]="translate.get('LOGIN.CREATE_ACCOUNT') | async"
                [disabled]="registerForm.invalid || (!recaptchaConfirmation && !hideRecaptcha) || (showTC && (!this.registerForm.get('acceptTC').value || !this.registerForm.get('acceptGDPR').value))"
                hfColor="primary" styleType="primary" type="submit" width="100%">
            </hf-text-button>
            <ng-content></ng-content>
        </form>
    </div>
    <mat-label class="login-label">
        {{ 'LOGIN.LOGIN_TEXT' | translate }} <b (click)="login()">{{ 'LOGIN.LOGIN' | translate}}</b>
    </mat-label>
</div>