<mat-sidenav-container class="sidenav-container">
  <mat-sidenav
    #drawer
    class="sidenav sidenav__{{ colorScheme }}"
    fixedInViewport
    [attr.role]="isMobile ? 'dialog' : 'navigation'"
    [mode]="isMobile ? 'over' : 'side'"
    [opened]="!isMobile && showMenu"
    disableClose
  >
    <div class="sidenav-container__close-button">
      <hf-icon-button
        (click)="drawer.toggle()"
        *ngIf="isMobile && showTopMenu"
        styleType="ghost"
        hfColor="white"
        icon="close"
      ></hf-icon-button>
      <hf-icon-button
        *ngIf="!showTopMenu && isMobile"
        (click)="drawer.toggle()"
        styleType="ghost"
        hfColor="secondary"
        icon="close"
      ></hf-icon-button>
    </div>
    <ng-content select="[sidebar-logo]"></ng-content>

    <mat-list [class.gap]="addGapToMenuItems" hfSideBar hfColor="accent-bg" *ngIf="showPages">
      <hf-menu-item
        *ngFor="let page of source"
        [iconLeft]="page.iconLeft"
        [iconRight]="page.iconRight"
        [increasePadding]="addGapToMenuItems"
        [item]="page.label"
        [routerLink]="page.url"
        [rightIconUrl]="page.rightIconUrl"
        [iconPath]="page.iconPath"
        [disabled]="page.disabled"
        [count]="page.count"
        [colorScheme]="colorScheme"
        (click)="isMobile ? drawer.toggle() : null"
      ></hf-menu-item>
      <ng-content select="[support]"></ng-content>
    </mat-list>
    <ng-content select="[sidebar-footer]"></ng-content>
  </mat-sidenav>

  <mat-sidenav-content>
    <div [ngClass]="{ 'background-assets': showBackgroundAssets }"></div>
    <hf-top-toolbar
      *ngIf="showMenu && showTopMenu"
      [style.width]="isMobile ? '100%' : ''"
      [isMobile]="isMobile"
      [fullName]="fullName"
      [dropdownItems]="dropdownItems"
      [actionButtonTitle]="actionButtonTitle"
      (toggleDrawer)="drawer.toggle()"
    >
      <ng-content></ng-content>
    </hf-top-toolbar>
    <hf-icon-button
      *ngIf="!hideBurger && !showTopMenu && isMobile"
      (click)="drawer.toggle()"
      styleType="ghost"
      hfColor="secondary"
      icon="menu"
    ></hf-icon-button>
    <div
      class="page-content"
      [class.page-content__clear-padding]="removeContentPadding"
      [class.page-content__clear_topbar_padding]="!showTopMenu"
	    [class.page-content__clear_bottom_padding]="!showTopMenu || removeBottomPadding"
    >
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
