<mat-radio-group [class]="aligned" [class]="type" [formControl]="radioControl" [ngClass]="class">
  <ng-container *ngIf="type === 'primary'">
    <mat-radio-button *ngFor="let item of source" [value]="item.value" [labelPosition]="item.labelPosition" [disabled]="item.disabled" (change)="changeValue($event)">
      <span *ngIf="item.translatableLabel">
        {{ item.label | translate }}
      </span>
      <span *ngIf="!item.translatableLabel">
        {{ item.label }}
      </span>
    </mat-radio-button>
  </ng-container>
  <ng-container *ngIf="type === 'secondary'">
    <hf-card *ngFor="let item of source" class="secondary--card" fullHeight="true" removePadding="true">
      <mat-radio-button [value]="item.value" [disabled]="item.disabled" (change)="changeValue($event)">
        <div class="secondary--card__icon-bg">
          <img [src]="icon" *ngFor="let icon of item.iconPaths" alt="" />
        </div>
        <br />
        <h2>{{ item.label | translate }}</h2>
        <p>{{ item.text | translate }}</p>
      </mat-radio-button>
    </hf-card>
  </ng-container>
  <ng-container *ngIf="type === 'tertiary'">
    <div *ngFor="let item of source" class="tertiary__card">
      <mat-radio-button [value]="item.value" [disabled]="item.disabled" (change)="changeValue($event)">
        <p class="title">{{ item.label | translate }}</p>
        <p class="text">{{ item.text | translate }}</p>
      </mat-radio-button>
    </div>
  </ng-container>
</mat-radio-group>
<mat-hint class="input-error" [id]="null" *ngIf="error">
  {{ error }}
</mat-hint>
