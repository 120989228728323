import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

import * as UserActions from '../actions/user.actions';
import { AuthService } from '../../auth/auth.service';
import { User } from 'honeyfield-component-library';

@Injectable()
export class UserEffects {

  loginUser$ = createEffect(() => (this.actions$.pipe(
      ofType(UserActions.loginUser),
      switchMap(action => (
          this.authService.login(action.email, action.password, action.persist).pipe(
            map((user: User) => UserActions.loginUserSuccess({ user })),
            catchError(err => {
              console.error('Login error: ', err);
              return of(UserActions.loginUserFailure(err));
            })))))));

  registerUser$ = createEffect(() => (this.actions$.pipe(
      ofType(UserActions.registerUser),
      switchMap(action => (this.authService.register(action.email, action.password, action.fullName).pipe(
            map((success) => UserActions.registerUserSuccess({})),
            catchError(err => {
              console.error('Register error: ', err);
              return of(UserActions.loginUserFailure(err));
            })))))));

  constructor(private actions$: Actions,
              private authService: AuthService) {
  }

}
