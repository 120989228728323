import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { User } from 'honeyfield-shared-library';
import { Router } from '@angular/router';

export enum MessageBoxEnum {
  message,
  startCommunication,
  requestConfirmed,
  handOverCompleted,
  bookingCompleted,
  reminder,
  rentCompleted,
  requestDeclined,
  bookingWillBeChecked,
}

export class MessageBox {
  icon?: string;
  eventName?: string;
  text?: string;
  type: MessageBoxEnum;
  data?: any;
}

@Component({
  selector: 'hf-messages-box',
  templateUrl: './messages-box.component.html',
  styleUrls: ['./messages-box.component.scss'],
})
export class MessagesBoxComponent implements OnInit {
  public messageBoxes: MessageBox[];
  public messageType = MessageBoxEnum;

  @Input() type: MessageBoxEnum = MessageBoxEnum.startCommunication;
  @Input() additionalData: any;
  @Input() message: any;
  @Input() currentUser: User;
  @Output() eventClicked = new EventEmitter<MessageBox>();

  data: MessageBox;
  isSenderUser: boolean;

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.isSenderUser = this.additionalData?.sender === this.currentUser._id;
    this.messageBoxes = [
      {
        text: 'CHAT.START_COMMUNICATION',
        eventName: '',
        icon: 'hf-checkmark',
        type: MessageBoxEnum.startCommunication,
      },
      {
        text: !this.isSenderUser ? 'RENTALS.CONFIRMED_STATUS_MESSAGE_RENTER' : 'RENTALS.CONFIRMED_STATUS_MESSAGE',
        eventName: this.router.url.includes('profile') ? 'CHAT.DETAILS' : '',
        icon: 'hf-checkmark',
        type: MessageBoxEnum.requestConfirmed,
      },
      {
        text: 'CHAT.HANDOVER',
        eventName: 'CHAT.CONFIRM',
        icon: 'questionmark',
        type: MessageBoxEnum.handOverCompleted,
      },
      {
        text: 'CHAT.BOOKING_COMPLETE',
        eventName: 'CHAT.DETAILS',
        icon: 'hf-checkmark',
        type: MessageBoxEnum.bookingCompleted,
      },
      {
        text: 'CHAT.24H_BEFORE',
        icon: 'hf-icon-reminder',
        type: MessageBoxEnum.reminder,
      },
      {
        text: 'CHAT.RENT_COMPLETED',
        eventName: 'CHAT.REVIEW',
        icon: 'hf-icon-feedback',
        type: MessageBoxEnum.rentCompleted,
      },
      {
        text: 'CHAT.DECLINED',
        icon: 'hf-icon-declined',
        type: MessageBoxEnum.requestDeclined,
      },
      {
        text: 'CHAT.REQUEST_CHECK',
        icon: 'hf-checkmark',
        type: MessageBoxEnum.bookingWillBeChecked,
      },
    ];
    this.setData();
  }

  setData() {
    this.data = this.messageBoxes.find((m) => m.type === this.type);

    if (this.data && this.data.type === MessageBoxEnum.startCommunication) {
      this.data.text =
        !this.currentUser?.id === this.additionalData?.sender
          ? this.data.text.replace('lessor', 'renter')
          : this.data.text;
      this.data = { ...this.data };
    }
  }

  eventClickedHandler(data: MessageBox) {
    this.eventClicked.emit(data);
  }
}
