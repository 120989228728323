<div class="generic-table-header" [style.grid-template-columns]="gridColumnTemplate" [class.fit-content]="xScrollable">

  <div class="generic-table-header-item generic-table-header-item__select" [class.padding-0]="!selectable">
    <mat-checkbox *ngIf="selectable" (change)="$event ? masterToggle() : null"
                  [checked]="selection.hasValue() && isAllSelected()"
                  [indeterminate]="selection.hasValue() && !isAllSelected()">
    </mat-checkbox>
  </div>

  <div class="generic-table-header-item--start" *ngIf="showStart">
    <ng-content select="[role=start]"></ng-content>
  </div>

  <ng-container *ngIf="startColumnsInfo !== null">
    <ng-container *ngFor="let item of startColumnsInfo">
      <div class="generic-table-header-item--start-sticky"
           [ngStyle]="{'left': item.leftPx + (selectable ? 50 : 0) + 'px' }">
        <ng-container *ngIf="startTemplateRef" [ngTemplateOutlet]="startTemplateRef"
                      [ngTemplateOutletContext]="{$implicit:item}">
        </ng-container>
      </div>
    </ng-container>
  </ng-container>

  <ng-container *ngFor="let column of columnData">
    <div class="generic-table-header-item" [ngStyle]="getStyle(column)" [class.min-width-250]="xScrollable">
      {{ column.displayName | translate }}
    </div>
  </ng-container>

  <ng-container *ngIf="endColumnsInfo !== null">
    <ng-container *ngFor="let item of endColumnsInfo">
      <div class="generic-table-header-item--end-sticky" [ngStyle]="{'right': item.rightPx + 'px'}">
        <ng-container *ngIf="endTemplateRef" [ngTemplateOutlet]="endTemplateRef"
                      [ngTemplateOutletContext]="{$implicit:item}">
        </ng-container>
      </div>
    </ng-container>
  </ng-container>

  <div class="generic-table-header-item--end" *ngIf="showActions">
    <ng-content select="[role=end]"></ng-content>
  </div>
</div>
