import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { HFValidationConfig } from '../../../interfaces';

@Component({
  selector: 'hf-login-v2',
  templateUrl: './login-v2.component.html',
  styleUrls: ['./login-v2.component.scss']
})
export class LoginV2Component implements OnInit, OnDestroy {

  @Input() validationConfig: HFValidationConfig;
  @Input() showShadows = true;
  @Input() registerTitleColor: string;
  @Output() toggleForm: EventEmitter<any> = new EventEmitter();
  @Output() submitLogin: EventEmitter<any> = new EventEmitter();
  @Output() loginViaGoogle: EventEmitter<any> = new EventEmitter();
  @Output() toggleForgotPassword: EventEmitter<any> = new EventEmitter();
  loginForm: FormGroup;
  emailError: string;
  passwordError: string;
  emailPlaceholder = 'GENERAL.EMAIL';
  passwordPlaceholder = 'GENERAL.PASSWORD';

  private subscriptions = new Subscription();

  constructor(private fb: FormBuilder, public translate: TranslateService) {}

  ngOnInit(): void {
    this.loginForm = this.fb.group({
      email: new FormControl('', this.validationConfig?.login?.email?.validator),
      password: new FormControl('', this.validationConfig?.login?.password?.validator),
      persist: new FormControl(''),
    });

    this.subscriptions = this.loginForm.valueChanges.pipe(debounceTime(300)).subscribe(() => {
      if (this.loginForm.get('email').hasError('email')) {
        this.emailError = this.validationConfig?.login?.email?.errorMessages?.invalid;
      } else {
        this.emailError = '';
      }

      if (this.loginForm.get('password').dirty) {
        this.passwordError = '';
      }
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  submit() {
    if (this.loginForm.valid) {
      const value = ['login', this.loginForm?.value];
      this.submitLogin.emit(value);
    } else {
      if (this.loginForm.get('email').hasError('required')) {
        this.emailError = this.validationConfig?.login?.email?.errorMessages?.required;
      } else if (this.loginForm.get('email').hasError('email')) {
        this.emailError = this.validationConfig?.login?.email?.errorMessages?.invalid;
      } else {
        this.emailError = '';
      }

      if (this.loginForm.get('password').hasError('required')) {
        this.passwordError = this.validationConfig?.login?.password?.errorMessages?.required;
      } else {
        this.passwordError = '';
      }
    }
  }

  getErrors(error: string) {
    if (error === 'email') {
      return (
        (this.emailError && (this.loginForm.get('email').touched || this.loginForm.get('email').dirty)) ||
        (this.emailError && this.loginForm.get('email').pristine)
      );
    } else if (error === 'password') {
      return (
        (this.passwordError && (this.loginForm.get('password').touched || this.loginForm.get('password').dirty)) ||
        (this.passwordError && this.loginForm.get('password').pristine)
      );
    }
  }

  register() {
    this.toggleForm.emit();
  }

  forgotPassword() {
    this.toggleForgotPassword.emit();
  }

  submitLoginViaGoogle() {
    this.loginViaGoogle.emit();
  }

  submitLoginViaFacebook() {
    alert('Not implemented');
  }

}
