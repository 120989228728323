<ng-container
  *ngIf="message?.type === messageType.startCommunication && message?.request?.rentable_item?.Name; else otherType">
  <div class="box-info reservation">
    <hf-reservation-message [currentUser]="currentUser" [message]="message"></hf-reservation-message>
  </div>
</ng-container>
<ng-template #otherType>
  <div class="box-info" *ngIf="data">
    <mat-icon *ngIf="data.icon" [svgIcon]="data.icon"></mat-icon>
    <p class="box-info__text">
      {{ data.text | translate }}
    </p>
    <a (click)="eventClickedHandler(data)" *ngIf="data.eventName">{{ data.eventName | translate }}</a>
  </div>
</ng-template>
