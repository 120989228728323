import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'hf-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
})
export class PaginatorComponent implements OnInit {
  @Input() totalItems;
  @Input() itemsPerPage;

  @Output() currentPageChange = new EventEmitter<number>();

  totalPages = 1;
  currentPage = 1;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {}

  setTotalPages(e) {
    this.totalPages = e;
    this.cdr.detectChanges();
  }

  onPageChange($event) {
    this.currentPage = $event;
    this.currentPageChange.emit(this.currentPage);
  }
}
