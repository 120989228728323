import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthService } from './auth.service';
import { Location } from '@angular/common';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(public authService: AuthService,
              private location: Location,
              private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {

    if (!this.authService.isUserLoggedIn()) {
      this.router.navigateByUrl('login');
      return of(false);
    } else {
      return of(true);
    }
  }
}
