<mat-slide-toggle
  hfSliderToggle
  [hfColor]="hfColor"
  [checked]="checked"
  [labelPosition]="labelPosition"
  [required]="required"
  (toggleChange)="setValue()"
  [disabled]="disabled">
  {{ text }}
</mat-slide-toggle>
