import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { StrapiFile, UploadFileData } from '../../models/api-interfaces';
import { UtilsService } from '../../services/utils.service';
import { ImagePreviewComponent } from './image-preview/image-preview.component';
import { UploadHelperService } from 'honeyfield-shared-library';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'hf-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent implements OnInit, OnDestroy {
  @Input() color = '#fafafa';
  @Input() padding = '0';
  @Input() showUploadedImages = true;
  @Input() showTitle = true;
  @Input() showAllowableFormats = true;
  @Input() fileData: UploadFileData = { maximumSize: 5, allowedFormats: ['jpg', 'png', 'jpeg'] };
  @Input() files: StrapiFile[];

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onFilesUploaded: EventEmitter<FileList> = new EventEmitter();
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onFileDeleted: EventEmitter<string> = new EventEmitter();

  public title = 'GENERAL.IMAGE_UPLOAD';
  public mobileTitle = 'GENERAL.IMAGE_UPLOAD_MOBILE';

  public get isMobile() {
    return this.utilsService.isMobile;
  }

  private dialogWidth = '1024px';
  private maxWidth = '80vw';

  public uploadProgress: number;

  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    public utilsService: UtilsService,
    public dialog: MatDialog,
    public uploadHelperService: UploadHelperService
  ) {}

  ngOnInit(): void {
    this.uploadHelperService.uploadProgess$.pipe(takeUntil(this.destroy$)).subscribe((progress) => {
      this.uploadProgress = progress;
    });
  }

  uploadFile(files: FileList) {
    this.onFilesUploaded.emit(files);
  }

  public openImageDialog(imageIndex): void {
    this.dialog.open(ImagePreviewComponent, {
      disableClose: false,
      width: this.dialogWidth,
      maxWidth: this.maxWidth,
      data: { files: this.files, index: imageIndex },
    });
  }

  deleteFile(id: string) {
    this.onFileDeleted.emit(id);
  }

  setTitle(event) {
    this.title = event;
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
