import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { HFRadioItem } from '../../interfaces/hf-radio.interface';

@Component({
  selector: 'hf-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.scss'],
  providers: [
      {
          provide: NG_VALUE_ACCESSOR,
          useExisting: forwardRef(() => RadioButtonComponent),
          multi: true
      }
  ]
})

export class RadioButtonComponent implements OnInit, ControlValueAccessor {

  @Input() value: string;
  @Input() label: string;
  @Input() labelPosition: string;
  @Input() disabled = false;
  @Input() source: HFRadioItem[];
  @Input() aligned: string;
  @Input() error: string;
  @Input() type:  'primary' | 'secondary' | 'tertiary' = 'primary';
  @Input() class: string;
  
  radioControl = new FormControl({ disabled: this.disabled });

  private onChange = (change: any) => {};
  private onTouched = () => {};

  constructor(public translate: TranslateService) {
  }

  ngOnInit(): void {
  }

  writeValue(value: string): void {
    this.radioControl.setValue(value);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  changeValue(event) {
    this.radioControl.setValue(event.value);
    this.onChange(this.radioControl.value);
  }

}
