import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { SliderComponent } from './slider.component';
import { MatSliderModule } from '@angular/material/slider';
import { ColorModule } from '../colors/color.module';


@NgModule({
  declarations: [SliderComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSliderModule,
    ColorModule
  ],
  exports: [SliderComponent]
})
export class SliderModule {
}
