<div class='forgot-password-v2-container'>
    <ng-container *ngIf="!completedResetPassword; else finishRecovery">
            <ng-container *ngIf="step === 1">
                <mat-card class='forgot-password-v2-container__step-one'>
                    <h2 class='forgot-password-v2-container__step-one__title'>{{'FORGOT_PASSWORD.STEP_ONE.TITLE' | translate}}</h2>
                    <h5 class='forgot-password-v2-container__step-one__subtitle'>{{'FORGOT_PASSWORD.STEP_ONE.SUBTITLE' | translate}}
                    </h5>
                    <form class='forgot-password-v2-container__step-one__form' [formGroup]='forgotPasswordForm' (ngSubmit)="submit()">
                        <hf-input-text class='forgot-password-v2-container__step-one__form__email-input'
                            [placeholder]="emailPlaceholder | translate" iconAlign="right" icon="email" formControlName="email"
                            type="email" email="true">
                        </hf-input-text>
                        <div class="error-container">
                            <mat-hint *ngIf="(emailError && (forgotPasswordForm.get('email').touched || forgotPasswordForm.get('email').dirty))
                                      || (emailError && forgotPasswordForm.get('email').pristine)">
                                {{ emailError | translate }}
                            </mat-hint>
                        </div>
                        <br>
                        <hf-text-button class='forgot-password-v2-container__step-one__form__submit'
                            [text]="('FORGOT_PASSWORD.STEP_ONE.SUBMIT_BUTTON' | translate)" hfColor="primary" styleType="primary"
                            type="submit" width="100%">
                        </hf-text-button>
                    </form>
                </mat-card>
                <mat-label (click)='goToLogin()' class="login-label">
                    {{'FORGOT_PASSWORD.STEP_ONE.GO_TO_LOGIN_LABEL' | translate}}
                </mat-label>
            </ng-container>
            <ng-container *ngIf="step === 2">
                <mat-card class="forgot-password-container__step-two">
                    <h2 class="forgot-password-container__step-two__title">{{'FORGOT_PASSWORD.STEP_TWO.TITLE' | translate}}</h2>
                    <h5 class="forgot-password-container__step-two__subtitle">{{'FORGOT_PASSWORD.STEP_TWO.SUBTITLE' | translate}}
                    </h5>
                    <h5 class="forgot-password-container__step-two__email"> {{email}}</h5>
                    <hf-text-button (click)='proceed()' class="forgot-password-container__step-two__[roceed"
                        [text]="('FORGOT_PASSWORD.STEP_TWO.SUBMIT_BUTTON' | translate)" hfColor="primary" styleType="primary"
                        type="submit" width="100%">
                    </hf-text-button>
                </mat-card>
                <mat-label class="try-another-mail-label">
                    {{'FORGOT_PASSWORD.STEP_TWO.DID_NOT_RECIEVE_EMAIL_LABEL' | translate}}<b (click)="step=1">
                        {{'FORGOT_PASSWORD.STEP_TWO.TRY_ANOTHER_EMAIL_LABEL' | translate}} </b>
                </mat-label>
            </ng-container>
            <ng-container *ngIf="step === 3">
                <mat-card class="forgot-password-container__step-three">
                    <h2 class="forgot-password-container__step-three__title">{{'FORGOT_PASSWORD.STEP_THREE.TITLE' | translate}}</h2>
                    <h5 class="forgot-password-container__step-three__subtitle">{{'FORGOT_PASSWORD.STEP_THREE.SUBTITLE' |
                        translate}}</h5>
                    <form class="forgot-password-container__step-three__form" [formGroup]='resetPasswordForm'
                        (ngSubmit)="submitResetPassword()">
                        <hf-input-text class="forgot-password-container__step-three__form__old-password"
                            [placeholder]="newPasswordPlaceholder | translate" iconAlign="right" icon="lock"
                            formControlName="newPassword" type="password">
                        </hf-input-text>
                        <div class="hint-container">
                            <div class="hint-container__row">
                                <mat-hint
                                    [ngClass]="!this.resetPasswordForm.get('newPassword').value ? 'hint-info-color' : this.resetPasswordForm.get('newPassword').value && numPatternCheck ? 'hint-success-color' : 'hint-danger-color'">
                                    <span>
                                        <mat-icon>{{(this.resetPasswordForm.get('newPassword').value && numPatternCheck ||
                                            !this.resetPasswordForm.get('newPassword').value) ? 'check' : 'close'}}</mat-icon>
                                        {{'GENERAL.ONE_NUMBER' | translate}}
                                    </span>
                                </mat-hint>
                                <mat-hint
                                    [ngClass]="!this.resetPasswordForm.get('newPassword').value ? 'hint-info-color' : this.resetPasswordForm.get('newPassword').value && specialCharPattern ? 'hint-success-color' : 'hint-danger-color'">
                                    <span>
                                        <mat-icon>{{(this.resetPasswordForm.get('newPassword').value && specialCharPattern ||
                                            !this.resetPasswordForm.get('newPassword').value) ? 'check' : 'close'}}</mat-icon>
                                        {{'GENERAL.ONE_SPECIAL_CHARACTER' | translate}}
                                    </span>
                                </mat-hint>
                            </div>
                            <div class="hint-container__row">
                                <mat-hint
                                    [ngClass]="!this.resetPasswordForm.get('newPassword').value ? 'hint-info-color' : this.resetPasswordForm.get('newPassword').value && uppercaseCharacterPattern ? 'hint-success-color' : 'hint-danger-color'">
                                    <span>
                                        <mat-icon>{{(this.resetPasswordForm.get('newPassword').value && uppercaseCharacterPattern ||
                                            !this.resetPasswordForm.get('newPassword').value) ? 'check' : 'close'}}</mat-icon>
                                        {{'GENERAL.ONE_UPPERCASE_CHARACTER' | translate}}
                                    </span>
                                </mat-hint>
                                <mat-hint
                                    [ngClass]="!this.resetPasswordForm.get('newPassword').value ? 'hint-info-color' : this.resetPasswordForm.get('newPassword').value && minLengthPattern ? 'hint-success-color' : 'hint-danger-color'">
                                    <span>
                                        <mat-icon>{{(this.resetPasswordForm.get('newPassword').value && minLengthPattern ||
                                            !this.resetPasswordForm.get('newPassword').value) ? 'check' : 'close'}}</mat-icon>
                                        {{'GENERAL.MIN_LENGTH_8' | translate}}
                                    </span>
                                </mat-hint>
                            </div>
                        </div>
                        <password-strength-meter class="mt-20" [password]="this.resetPasswordForm.get('newPassword').value"
                            [minPasswordLength]="8"></password-strength-meter>
                        <hf-input-text [placeholder]="repeatPasswordPlaceholder | translate" iconAlign="right" icon="lock"
                            formControlName="repeatPassword" type="password"
                            [value]="this.resetPasswordForm.get('repeatPassword').value" required="true">
                        </hf-input-text>
                        <mat-error *ngIf="this.resetPasswordForm.get('repeatPassword').hasError('matching')">
                            {{'ERRORS.PASSWORDS_MATCH' | translate}}
                        </mat-error>
                        <hf-input-text class="forgot-password-container__step-three__form__new-password"
                            [placeholder]="codePlaceholder | translate" formControlName="verificationCode" type="text">
                        </hf-input-text>
                        <div class="error-container error-contianer-code-error">
                            <mat-hint *ngIf="(codeError && (resetPasswordForm.get('verificationCode').touched || resetPasswordForm.get('verificationCode').dirty))
                                                || (codeError && resetPasswordForm.get('verificationCode').pristine)">
                                {{ codeError | translate }}
                            </mat-hint>
                        </div>
                        <br>
                        <hf-text-button class="forgot-password-container__step-three__form__submit"
                            [text]="('FORGOT_PASSWORD.STEP_THREE.SUBMIT_BUTTON' | translate)" hfColor="primary" styleType="primary"
                            type="submit" width="100%">
                        </hf-text-button>
                    </form>
                </mat-card>
            </ng-container>
    </ng-container>
    <ng-template #finishRecovery>
        <mat-card class="forgot-password-container__step-four">
            <h2 class="forgot-password-container__step-four__title">{{'FORGOT_PASSWORD.STEP_FOUR.TITLE' | translate}}</h2>
            <h5 class="forgot-password-container__step-four__subtitle">{{'FORGOT_PASSWORD.STEP_FOUR.SUBTITLE' | translate}}
            </h5>
            <hf-text-button (click)='goToLogin()' class="forgot-password-container__step-four__go-to-login"
                [text]="('FORGOT_PASSWORD.STEP_FOUR.SUBMIT_BUTTON' | translate)" hfColor="primary" styleType="primary"
                type="submit" width="100%">
            </hf-text-button>
        </mat-card>
    </ng-template>
</div>
