import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UtilsService } from 'honeyfield-component-library';

import { Pagination, RentableItem } from '@shared-library-models/api-interfaces';
import { Rental } from 'honeyfield-shared-library';

@Injectable({
  providedIn: 'root',
})
export class ItemService {
  private itemEndpoint = `${this.environment.apiEndpoint}/rentable-items`;

  constructor(private http: HttpClient, @Inject('env') private environment) { }

  public getRentableItems(
    query: any = null,
    sort: string = 'createdAt:DESC',
    pagination: Pagination = null
  ): Observable<RentableItem[]> {
    let url = `${this.itemEndpoint}`;

    if (query) {
      delete query.sort;
      url = UtilsService.createUrlFromParams(url, query);
      url = `${url}_sort=${sort}`;
    } else if (pagination) {
      url = `${url}?_sort=${sort}&_start=${!pagination.start ? 0 : pagination.start}&_limit=${!pagination.limit ? 10 : pagination.limit
        }`;
    } else {
      url = `${url}?_sort=${sort}`;
    }
    return this.http.get<RentableItem[]>(url);
  }

  getItemCount(): Observable<number> {
    const url = `${this.itemEndpoint}/count?`;
    return this.http.get<number>(url);
  }

  public getReservedRentals(query): Observable<RentableItem[]> {
    let url = `${this.itemEndpoint}/reservedForDate`;
    url = UtilsService.createUrlFromParams(url, query);
    return this.http.get<RentableItem[]>(url);
  }

  public addRentableItem(item: RentableItem): Observable<RentableItem> {
    return this.http.post<RentableItem>(this.itemEndpoint, item);
  }

  public updateRentableItem(item: RentableItem): Observable<RentableItem> {
    const url = `${this.itemEndpoint}/${item._id}`;
    return this.http.put<RentableItem>(url, item);
  }

  public deleteRentableItem(item: RentableItem): Observable<RentableItem> {
    const url = `${this.itemEndpoint}/${item._id}`;
    return this.http.delete<RentableItem>(url);
  }

  public findItemById(itemId: string): Observable<RentableItem> {
    const url = `${this.itemEndpoint}/${itemId}`;
    return this.http.get<RentableItem>(url);
  }
  
  public getItemReservedDates(id: string) {
    const url = `${this.itemEndpoint}/itemReservedDates/${id}`;
    return this.http.get<string[]>(url);
  }
}
