import { Component, ElementRef, forwardRef, Input, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Observable, Subject } from 'rxjs';
import { debounceTime, map, startWith, takeUntil } from 'rxjs/operators';
import { HFSearchItem } from '../../interfaces/hf-search-item.interface';
@Component({
  selector: 'hf-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => SearchComponent),
    },
  ],
})
export class SearchComponent implements OnInit, ControlValueAccessor, OnDestroy {
  @Input() placeholder: string;
  @Input() value: string;
  @Input() iconAlign: string;
  @Input() icon: string;
  @Input() type: string;
  @Input() width: string;
  @Input() autoCompleteFrom: string;
  @Input() autocompleteData: HFSearchItem[];
  @Input() addBorderRadius = false;

  @Output() optionSelect: EventEmitter<HFSearchItem> = new EventEmitter<HFSearchItem>();

  public searchInput = new FormControl('');

  public filteredItems: Observable<HFSearchItem[]>;
  private destroy$: Subject<boolean> = new Subject<boolean>();

  private onChange: (name: string) => void;
  private onTouched: () => void;

  constructor(private element: ElementRef) {}

  ngOnInit() {
    this.element.nativeElement.classList.add('search-input');

    if (this.autocompleteData) {
      this.filteredItems = this.searchInput.valueChanges.pipe(
        takeUntil(this.destroy$),
        debounceTime(300),
        startWith(''),
        map((value) => this._filter(value))
      );
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }

  displayFn(result: HFSearchItem): string | undefined {
    return result ? result.label : undefined;
  }

  public updateSelection(event: MatAutocompleteSelectedEvent) {
    this.optionSelect.emit(event.option.value);
  }

  writeValue(input: any): void {
    this.searchInput.setValue(input);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.searchInput.disable();
    } else {
      this.searchInput.enable();
    }
  }

  doInput(event) {
    this.onChange(event.target.value);
  }

  doBlur() {
    this.onTouched();
  }

  emitValue() {
    this.optionSelect.emit(this.searchInput.value);
  }

  private _filter(value: string): HFSearchItem[] {
    const filterValue = value.toLowerCase();
    return this.autocompleteData.filter((option) => option.label?.toLowerCase().indexOf(filterValue) === 0);
  }
}
