<div class="login-container">
  <hf-card [showShadows]="showShadows">
    <h2>{{ 'LOGIN.LOGIN' | translate }}</h2>
    <div class="social-media-login" (click)="submitLoginViaGoogle()">
      <div class="icon"></div>
      <span>{{ 'LOGIN.CONTINUE_WITH_GOOGLE' | translate }}</span>
    </div>
    <div class="spacer">
      <div class="line"></div>
      <span>{{ 'GENERAL.OR' | translate }}</span>
      <div class="line"></div>
    </div>
    <form [formGroup]="loginForm" (ngSubmit)="submit()">
      <hf-input-text
        [placeholder]="emailPlaceholder | translate"
        iconAlign="right"
        icon="email"
        formControlName="email"
        type="email"
        email="true"
      ></hf-input-text>
      <div class="error-container">
        <mat-hint *ngIf="getErrors('email')">
          {{ emailError | translate }}
        </mat-hint>
      </div>
      <hf-input-text
        [placeholder]="passwordPlaceholder | translate"
        iconAlign="right"
        icon="lock"
        formControlName="password"
        type="password"
      ></hf-input-text>
      <div class="error-container">
        <mat-hint *ngIf="getErrors('password')">
          {{ passwordError | translate }}
        </mat-hint>
      </div>
      <mat-label (click)="forgotPassword()">
        {{ 'LOGIN.FORGOT_PASSWORD' | translate }}
      </mat-label>
      <br />
      <br />
      <hf-text-button
        [text]="translate.get('LOGIN.LOGIN') | async"
        hfColor="primary"
        styleType="primary"
        type="submit"
        width="100%"
      ></hf-text-button>
      <div class="stay-loged-in">
        <hf-checkbox
          labelPosition="before"
          formControlName="persist"
          label="{{ 'LOGIN.STAY_LOGGED_IN' | translate }}"
        ></hf-checkbox>
      </div>
    </form>
  </hf-card>
  <mat-label class="register-label color-{{ registerTitleColor }}">
    {{ 'LOGIN.REGISTER_TEXT' | translate }}
    <b id="register_btn" (click)="register()">{{ 'LOGIN.REGISTER' | translate }}</b>
  </mat-label>
</div>
