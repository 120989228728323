import { Injectable } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Country } from '@soda-models/country';
import { HFSelectItem } from '../interfaces';
import moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  public isMobile = false;

  constructor(private breakpointObserver: BreakpointObserver) {
    this.registerBreakpointObserver();
  }

  public static createUrlFromParams(baseUrl: string, query: any, allowBooleanFalseFilter = false): string {
    let url = baseUrl + '?';
    Object.keys(query)
      .filter((key) => !!query[key] || allowBooleanFalseFilter)
      .forEach((key) => {
        if (!Array.isArray(query[key])) {
          url = `${url}${key}=${query[key]}&`;
        } else {
          query[key].forEach((elem) => (url = `${url}${key}=${elem}&`));
        }
      });
    return url;
  }

  public static getNumberOfDays(start: Date, end: Date) {
    const startDate = new Date(start.setHours(0, 0, 0));
    const endDate = new Date(end.setHours(0, 0, 0));
    const dayInMs = 24 * 60 * 60 * 1000;
    return Math.round((endDate.getTime() - startDate.getTime()) / dayInMs);
  }

  public static getHoursBetween(start: Date, end: Date) {
    const differenceInHours = Math.abs(start.getTime() - end.getTime()) / 1000 / 3600;
    return Math.round(differenceInHours * 10) / 10;
  }

  // Method for dynamically converting colors to accent colors
  // Best to use when getting colors from back-end
  /* eslint-disable no-bitwise */
  public lightenDarkenColor(col, amt) {
    let usePound = false;
    if (col[0] === '#') {
      col = col.slice(1);
      usePound = true;
    }
    const num = parseInt(col, 16);
    let r = (num >> 16) + amt;
    if (r > 255) {
      r = 255;
    } else if (r < 0) {
      r = 0;
    }
    let b = ((num >> 8) & 0x00ff) + amt;
    if (b > 255) {
      b = 255;
    } else if (b < 0) {
      b = 0;
    }
    let g = (num & 0x0000ff) + amt;
    if (g > 255) {
      g = 255;
    } else if (g < 0) {
      g = 0;
    }
    return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
  }

  public static passwordMatchValidator(matchTo: string, reverse?: boolean): ValidatorFn {
    const passwordValidator = (control: AbstractControl): ValidationErrors | null => {
      if (control.parent && reverse) {
        const c = (control.parent?.controls as any)[matchTo] as AbstractControl;
        if (c && c.dirty) {
          c.updateValueAndValidity();
        }
        return null;
      }
      return !!control.parent &&
        !!control.parent.value &&
        control.value === (control.parent?.controls as any)[matchTo].value
        ? null
        : { matching: true };
    };
    return passwordValidator;
  }

  /* eslint-enable no-bitwise */

  private registerBreakpointObserver() {
    this.breakpointObserver
      .observe([Breakpoints.HandsetLandscape, Breakpoints.HandsetPortrait, Breakpoints.TabletPortrait])
      .subscribe((result) => (result.matches ? (this.isMobile = true) : (this.isMobile = false)));
  }

  public splitCountries(allCountries: Country[]) {
    if (allCountries.length === 0) return;

    const suggestedCountriesList = ['Croatia', 'Austria', 'Germany'];
    const hfOtherCountries: HFSelectItem[] = [];
    const hfSuggestedCountries: HFSelectItem[] = [];

    allCountries.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));

    allCountries.map((country) => {
      if (suggestedCountriesList.includes(country.name)) {
        country.suggested = true;
      }
    });

    allCountries.forEach((country) => {
      const obj: HFSelectItem = {
        label: country.name,
        value: country.code,
        imgURL: country.flag,
      };
      if (country.suggested) {
        hfSuggestedCountries.push(obj);
      } else hfOtherCountries.push(obj);
    });

    return [hfSuggestedCountries, hfOtherCountries];
  }

  public createBusinessHoursLabels(
    startTime: string,
    endTime: string,
    interval: number,
    period,
    momentTimeFormat = 'HH:mm'
  ): HFSelectItem[] {
    const start = moment(startTime, momentTimeFormat);
    const end = moment(endTime, momentTimeFormat);
    const duration = end.diff(start, 'hours');
    const periodsInADay = moment.duration(duration, 'hours').as(period);
    const timeLabels: HFSelectItem[] = [];
    const startTimeMoment = moment(startTime, momentTimeFormat);

    for (let i = 0; i <= periodsInADay; i += interval) {
      startTimeMoment.add(i === 0 ? 0 : interval, period);
      const obj: HFSelectItem = {
        value: startTimeMoment.format(momentTimeFormat),
        label: startTimeMoment.format(momentTimeFormat),
      };
      timeLabels.push(obj);
    }
    return timeLabels;
  }

  emailFormat(control): any {
    if (control.pristine) {
      return null;
    }
    control.markAsTouched();
    if (/^([\w\d+\-\.]+)@{1}(([\w\d+\-]{1,67})|([\w\d+\-]+\.[\w\d+\-]{1,67}))\.(([a-zA-Z\d+]{2,4})(\.[a-zA-Z\d+]{2})?)$/.test(control.value)) {
      return null;
    }

    return {
      emailFormat: true
    };
  }
}
