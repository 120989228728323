import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingIndicationService {

  public static isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor() {
  }
}
