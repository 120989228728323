import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { debounceTime } from 'rxjs';
import { UtilsService } from '../../../services/utils.service';
import * as Constants from '../../../constants';

@Component({
  selector: 'hf-forgot-password-v2',
  templateUrl: './forgot-password-v2.component.html',
  styleUrls: ['./forgot-password-v2.component.scss']
})
export class ForgotPasswordV2Component implements OnInit {

  @Output() initiateForgotPassword: EventEmitter<any> = new EventEmitter();
  @Output() resetPassword: EventEmitter<any> = new EventEmitter();
  @Output() toggleLogin: EventEmitter<any> = new EventEmitter();
  @Input() completedResetPassword: boolean;

  forgotPasswordForm: FormGroup;
  resetPasswordForm: FormGroup;
  emailError: string;
  emailPlaceholder = 'GENERAL.EMAIL';
  passwordError: string;
  passwordPlaceholder = 'GENERAL.PASSWORD';
  newPasswordPlaceholder = 'FORGOT_PASSWORD.STEP_THREE.NEW_PASSWORRD_PLACEHOLDER';
  repeatPasswordPlaceholder = 'LOGIN.REPEAT_PASSWORD';
  codePlaceholder = 'FORGOT_PASSWORD.STEP_THREE.CODE_PLACEHOLDER';
  codeError: string;
  step = 1;
  email: string;
  numPatternCheck: boolean;
  specialCharPattern: boolean;
  uppercaseCharacterPattern: boolean;
  minLengthPattern: boolean;

  constructor(private fb: FormBuilder, public translate: TranslateService) {}

  ngOnInit(): void {
    this.forgotPasswordForm = this.fb.group({
      email: new FormControl('', [Validators.required, Validators.email]),
    });
    this.resetPasswordForm = this.fb.group({
      newPassword: new FormControl('', [
        Validators.required,
        UtilsService.passwordMatchValidator('repeatPassword', true),
      ]),
      repeatPassword: new FormControl('', {
        validators: [UtilsService.passwordMatchValidator('newPassword')],
        updateOn: 'blur',
      }),
      verificationCode: new FormControl('', [Validators.required]),
    });

    this.resetPasswordForm
      .get('newPassword')
      .valueChanges.pipe(debounceTime(300))
      .subscribe((val) => {
        this.numPatternCheck = Constants.numberPattern.test(val);
        this.specialCharPattern = Constants.specialCharacterPattern.test(val);
        this.minLengthPattern = val && val.length > 7;
        this.uppercaseCharacterPattern = Constants.uppercaseCharacterPattern.test(val);
      });

    this.onChangesForgotPasswordForm();
    this.onChangesResetPasswordForm();
  }

  onChangesForgotPasswordForm() {
    this.forgotPasswordForm.valueChanges.pipe(debounceTime(300)).subscribe((forgotPasswordForm) => {
      if (this.forgotPasswordForm.get('email').hasError('email')) {
        this.emailError = 'Email is invalid.';
      } else {
        this.emailError = '';
      }
    });
  }

  onChangesResetPasswordForm() {
    this.resetPasswordForm.valueChanges.pipe(debounceTime(300)).subscribe((resetPasswordForm) => {
      if (this.resetPasswordForm.get('newPassword').dirty) {
        this.passwordError = '';
      }
      if (this.resetPasswordForm.get('verificationCode').dirty) {
        this.codeError = '';
      }
    });
  }

  submit() {
    if (this.forgotPasswordForm.valid) {
      const value = ['forgot-password', this.forgotPasswordForm.value];
      this.email = this.forgotPasswordForm.get('email').value;
      this.initiateForgotPassword.emit(value);
      this.step++;
    } else {
      if (this.forgotPasswordForm.get('email').hasError('required')) {
        this.emailError = 'ERRORS.EMAIL_REQUIRED';
      } else if (this.forgotPasswordForm.get('email').hasError('email')) {
        this.emailError = 'ERRORS.EMAIL_INVALID';
      } else {
        this.emailError = '';
      }
    }
  }

  submitResetPassword() {
    if (this.resetPasswordForm.valid) {
      const value = ['reset-password', this.resetPasswordForm.value];
      this.resetPassword.emit(value);
    } else {
      if (this.resetPasswordForm.get('newPassword').hasError('required')) {
        this.passwordError = 'ERRORS.PASSWORD_REQUIRED';
      } else {
        this.passwordError = '';
      }

      if (this.resetPasswordForm.get('repeatPassword').hasError('')) {
        if (this.resetPasswordForm.get('verificationCode').hasError('required')) {
          this.codeError = 'ERRORS.CODE_REQUIRED';
        } else {
          this.codeError = '';
        }
      }
    }
  }

  goToLogin(): void {
    this.toggleLogin.emit();
  }

  proceed(): void {
    this.step++;
  }

}
