import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'hf-user-avatar',
  templateUrl: './user-avatar.component.html',
  styleUrls: ['./user-avatar.component.scss'],
})
export class UserAvatarComponent implements OnInit {
  @Input() public name: string;
  @Input() private color = 'black';
  @Input() private borderRadius = '50%';
  @Input() private cursorStyle = 'auto';
  @Input() public fontSize = '14px';

  public initials: string;
  public style;

  constructor() {}

  ngOnInit(): void {
    this.style = this.getStyle();
    if (this.name) {
      this.createInitials(this.name);
    }
  }

  private getStyle() {
    return {
      background: this.color,
      'border-radius': this.borderRadius,
      cursor: this.cursorStyle,
    };
  }

  private createInitials(nameString) {
    const fullName = nameString.split(' ');
    if (fullName.length > 1) {
      const initials = fullName.shift().charAt(0) + fullName.pop().charAt(0);
      this.initials = initials.toUpperCase();
    } else {
      this.initials = fullName.shift().charAt(0).toUpperCase();
    }
  }
}
