<div class="header">
  <h3 mat-dialog-title>
    {{selectedImageIndex + 1}} / {{data.files.length}}
  </h3>
  <hf-icon-button (click)="closeDialog()" icon="close" styleType="ghost" hfColor="primary">
  </hf-icon-button>
</div>
<mat-dialog-content *ngIf="data && data.files">
  <div class="display-image">
    <button class="button" mat-stroked-button (click)="previousImage()">
      <mat-icon inline="true">chevron_left</mat-icon>
    </button>
    <img src="{{ data.files[selectedImageIndex].url }}" />
    <button class="button" mat-stroked-button (click)="nextImage()">
      <mat-icon inline="true">chevron_right</mat-icon>
    </button>
  </div>
  <div class="buttons" *ngIf="isMobile">
    <button class="button" mat-stroked-button (click)="previousImage()">
      <mat-icon inline="true">chevron_left</mat-icon>
    </button>
    <button class="button" mat-stroked-button (click)="nextImage()">
      <mat-icon inline="true">chevron_right</mat-icon>
    </button>
  </div>
</mat-dialog-content>
