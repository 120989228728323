import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'hf-top-date',
  templateUrl: './top-date.component.html',
  styleUrls: ['./top-date.component.scss']
})
export class TopDateComponent implements OnInit {

  fullDate = new Date();

  constructor() { }

  ngOnInit(): void {
  }

}
