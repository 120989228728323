import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'hf-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => CheckboxComponent)
    }
  ]
})
export class CheckboxComponent implements OnInit, ControlValueAccessor {
  @Input() id: string;
  @Input() value: string;
  @Input() label: string;
  @Input() labelPosition: 'after' | 'before' = 'after';
  @Input() tooltipContent: string;

  public checkboxInput = new FormControl({ value: true, disabled: false });
  onChange = (value:any) => { };
  onTouched = () => { };

  constructor() {
  }

  ngOnInit(): void {
  }

  writeValue(input: boolean): void {
    this.checkboxInput.setValue(input);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean) {
    return isDisabled ? this.checkboxInput.disable() : this.checkboxInput.enable();
  }

  doInput() {
    this.onChange(!this.checkboxInput.value);
  }

  doBlur() {
    this.onTouched();
  }

}
