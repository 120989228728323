<div class="container">
    <ng-container *ngFor="let filter of filters">
        <div class="container__pillow-filter" [ngClass]="{'border': (filter && filter.value)}">
            <mat-icon class="container__icon" *ngIf="filter.icon">
                {{ filter.icon }}
            </mat-icon>
            <p class="container__custom-paragraph">{{ filter.value ? filter.value : filter.label }}</p>
        </div>
    </ng-container>
    <hf-text-button [text]="translate.get('GENERAL.CLEAR_FILTERS') | async" hfColor="primary" styleType="ghost" (click)="clear()">
    </hf-text-button>
    <div class="container__pillow-filter border push-right" *ngIf="showLowestPrice">
        <p class="container__custom-paragraph">{{ translate.get('GENERAL.LOWEST_PRICE') | async }}</p>
    </div>
</div>
<div *ngIf="showBreakLine" class="line-break"></div>