import {
  Directive,
  OnChanges,
  OnInit,
  SimpleChanges,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  Renderer2,
  HostListener,
} from '@angular/core';

@Directive({
  selector: '[hfPagination]',
  exportAs: 'hfPagination',
})
export class PaginationDirective implements OnChanges, OnInit {
  @Input() pageNo = 1;
  @Input() totalItems;
  @Input() itemsPerPage = 5;

  @Output() pageChange = new EventEmitter<number>();
  @Output() totalPagesChange = new EventEmitter<number>();

  totalPages: number;
  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit() {
    this.setValue(this.pageNo);
  }

  ngOnChanges({ pageNo, totalItems }: SimpleChanges) {
    if (totalItems) {
      this.onTotalItemsInput();
    }

    if (pageNo) {
      this.onPageNoInput();
    }
  }

  @HostListener('input', ['$event.target.value']) onInput(val) {
    this.setValue(this.getParsedValue(val));
  }

  @HostListener('change', ['$event.target.value']) onChange(val) {
    if (val === '') {
      this.setValue(1);
    }

    if (this.isOutOfRange(val)) {
      this.setValue(this.totalPages);
    }

    this.pageNo = Number(this.el.nativeElement.value);
    this.pageChange.emit(this.pageNo);
  }

  get isFirst(): boolean {
    return this.pageNo === 1;
  }

  get isLast(): boolean {
    return this.pageNo === this.totalPages;
  }

  first() {
    this.setPage(1);
  }

  prev() {
    this.setPage(Math.max(1, this.pageNo - 1));
  }

  next() {
    this.setPage(Math.min(this.totalPages, this.pageNo + 1));
  }

  last() {
    this.setPage(this.totalPages);
  }

  private setValue(val: string | number) {
    this.renderer.setProperty(this.el.nativeElement, 'value', String(val));
  }

  private setPage(val: number) {
    this.pageNo = val;
    this.setValue(this.pageNo);
    this.pageChange.emit(this.pageNo);
  }

  private getParsedValue(val: string): string {
    return val.replace(/(^0)|([^0-9]+$)/, '');
  }

  private isOutOfRange(val: string): boolean {
    return Number(val) > this.totalPages;
  }

  private onTotalItemsInput() {
    this.totalPages = Math.ceil(this.totalItems / this.itemsPerPage);
    this.totalPagesChange.emit(this.totalPages);
  }

  private onPageNoInput() {
    if (typeof this.pageNo !== 'number' || this.pageNo < 1 || this.pageNo > this.totalPages) {
      this.pageNo = 1;
    }

    this.setValue(this.pageNo);
  }
}
