import { Directive, Output, Input, EventEmitter, HostBinding, HostListener } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[hfDragDrop]'
})
export class DragDropDirective {

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onFileDropped = new EventEmitter<any>();
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onTitleChanged = new EventEmitter<any>();

  @HostBinding('style.background-color') public background = '#FFFFFF;';
  @HostBinding('style.height') public height = '80px';

  // Dragover listener
  @HostListener('dragover', ['$event']) onDragOver(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = '#FAFBFE';
    this.height = '400px';
    this.onTitleChanged.emit('Drop it here!');
  }

  // Dragleave listener
  @HostListener('dragleave', ['$event'])
  public onDragLeave(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = '#FFFFFF';
    this.height = '80px';
    this.onTitleChanged.emit('Drag & Drop files here or choose file');
  }

  // Drop listener
  @HostListener('drop', ['$event'])
  public ondrop(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = '#FFFFFF';
    this.height = '80px';
    this.onTitleChanged.emit('Drag & Drop files here or choose file');
    const files = evt.dataTransfer.files;
    if (files.length > 0) {
      this.onFileDropped.emit(files);
    }
  }

}
